import React from "react";

const NotCompletedResponse = () => {
  return (
    <p className="keep-practicing">
      That’s ok! Keep practicing and come back when you are ready to complete
      the checklist!
    </p>
  );
};

export default NotCompletedResponse;
