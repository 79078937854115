import React from "react";

const DropDown = ({ handleChange, name, optionsList, value }) => {
  return (
    <select name={name} value={value || ""} onChange={handleChange}>
      {optionsList.map((item, index) => (
        <option key={index}>{item}</option>
      ))}
    </select>
  );
};
export default DropDown;
