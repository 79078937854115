import React from 'react';
import { useParams } from 'react-router-dom';
import '../../../components/core/timeline/timeline.scss';
import '../../../components/shared/static-content/static-content.scss';
import './all-handouts.scss';
import {
  mod1,
  mod2,
  mod3,
  mod4,
  mod5,
  mod6,
} from '../../../assets/handouts/handoutSections';
import useStore from '../../../customHooks/useStore';
const renderPDFs = (number) => {
  switch (number) {
    case '1':
      return mod1;
    case '2':
      return mod2;
    case '3':
      return mod3;
    case '4':
      return mod4;
    case '5':
      return mod5;
    case '6':
      return mod6;
    default:
      return '404';
  }
};

console.log(mod1[0].target.en, 'check');

const AllHandouts = () => {
  let { id } = useParams();
  const { languageNow } = useStore((state) => ({
    languageNow: state.language,
  }));
  return (
    <>
      {id > 0 && id < 7 ? (
        <div className='handout-wrapper'>
          <h2 className='title'> Module {id} Handouts</h2>
          <div className='static-wrap '>
            <ul>
              {renderPDFs(id).map((item) => {
                return (
                  <li>
                    <a
                      href={
                        languageNow === 'en' ? item.target.en : item.target.es
                      }
                      target={'_blank'}
                      rel='noreferrer'
                    >
                      {item.name}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      ) : (
        <div>404 Error</div>
      )}
    </>
  );
};

export default AllHandouts;
