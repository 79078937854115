import React, { useState } from 'react';
import '.././PlanIt.scss';
import TextInput from '../../Form/TextInput';
import AddableTextInput from '../../Form/AddableTextInput';
import { useBadge } from '../../../customHooks/useBadge';
import ActivityBadge from '../../shared/badges/ActivityBadge';
export default function IgPractice(props) {
  const [misbehavior, setMisbehavior] = useState();
  const [submitedValue, setSubmitedValue] = useState(false);
  const [{ showBadge }, dispatch] = useBadge(false);
  const handleChange = (e) => setMisbehavior(e.target.value);

  const handleSubmit = () => {
    dispatch({ type: 'show', payload: props.badge });
    !submitedValue ? setSubmitedValue(misbehavior) : setSubmitedValue(false);
  };

  return (
    <>
      <div>
        <div className='question-wrapper'>
          <p>
            Pick one of the behaviors that you think you can ignore, what is it?
          </p>
          <AddableTextInput
            value={misbehavior}
            handleChange={handleChange}
            handleSubmit={handleSubmit}
            editable={!submitedValue}
            name={submitedValue ? 'Edit' : 'Save'}
          />
        </div>
        {submitedValue && (
          <p className='outro' onClick={() => setSubmitedValue(false)}>
            Then ignore the misbehavior every time you see it happen.
          </p>
        )}
      </div>
      <ActivityBadge
        showBadge={showBadge}
        content={props.badge}
        dispatch={dispatch}
      />
    </>
  );
}
