import { useGetData } from '../../../components/core/helperFuncs/handlePracticeGet';
import React from 'react';

export const FillIn = ({ question, dataVarName, type }) => {
  const [res] = useGetData(dataVarName);

  const getFormattedData = () => {
    if (res.data) {
      if (type === 'Array') {
        return res.data.join(', ');
      } else {
        return res.data;
      }
    }
    return false;
  };
  return (
    <>
      {question + ' '}
      <span style={{ textDecoration: 'underline' }}>
        {getFormattedData() ? getFormattedData() : <input type='text' />}
      </span>
    </>
  );
};
