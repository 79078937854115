import React from 'react';
import './image.scss';
import useStore from '../../../customHooks/useStore';
import SpanishImg3 from '../../../assets/images/stock-images/stock3es.jpg';
import SpanishImg5 from '../../../assets/images/stock-images/stock5es.jpg';
const Image = ({ number }) => {
  const { language } = useStore((state) => {
    return {
      language: state.language,
    };
  });

  if (language === 'es' && number === 3) {
    return (
      <div className='image-wrap'>
        <img src={SpanishImg3} alt='Stock' />
      </div>
    );
  }

  if (language === 'es' && number === 5) {
    return (
      <div className='image-wrap'>
        <img src={SpanishImg5} alt='Stock' />
      </div>
    );
  }

  return (
    <div className='image-wrap'>
      <img
        src={
          require(`../../../assets/images/stock-images/stock${number}.jpg`)
            .default
        }
        alt='Stock'
      />
    </div>
  );
};

export default Image;
