import React from 'react';
import GenStyles from '../../../GenStyles';
import styled from 'styled-components';
import redX from '../../../assets/redx.png';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import greenCheck from '../../../assets/greenCheck.png';
const S = {
  DateText: styled.span`
    font-size: 1rem;
    font-weight: bold;
    color: black;
    border-bottom: black solid 2px;
    margin-left: ${(props) => (props.badge ? '0' : '1.25rem')};
    margin-top: 0;
    color: ${(props) => (props.badge ? 'black' : '#5f2c86')};
  `,
  CompletedModules: styled.div`
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin-bottom: 0.25rem;
    font-weight: bold;
    white-space: normal;
    @media (min-width: 450px) {
      white-space: nowrap;
    }
    &:before {
      content: '';
      background-image: ${(props) =>
        props.checked ? `url(${greenCheck})` : `url(${redX})`};
      background-size: cover;
      display: inline-block;
      object-fit: cover;
      height: 15px;
      width: 15px;
      margin-right: 5px;
    }
  `,

  CompletedModulesWrapper: styled.li`
    margin: 1rem 0;
    list-style: none;
  `,
};

const ModuleStatus = ({ titleNames }) => {
  const numberOfCompletions = titleNames.filter(
    (item) => item.date !== undefined
  ).length;

  return (
    <GenStyles.UserItemWrapper>
      <GenStyles.InfoHeader>
        Completed Modules <span>{numberOfCompletions}/6</span>
      </GenStyles.InfoHeader>
      <ul style={{ padding: 0 }}>
        {titleNames.map((item, index) => (
          <S.CompletedModulesWrapper>
            <S.CompletedModules checked={item.date}>
              {item.title}
            </S.CompletedModules>
            {item.date && (
              <S.DateText>{format(parseISO(item.date), 'LL/dd/y')}</S.DateText>
            )}
          </S.CompletedModulesWrapper>
        ))}
      </ul>
    </GenStyles.UserItemWrapper>
  );
};

export default ModuleStatus;
