import React, { useContext } from 'react';
import { getGrammar } from '../../core/helperFuncs/grammar';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Context } from '../../../context';
const SummaryThree = () => {
  const [res] = useGetData('ds-practice-1');
  const context = useContext(Context);
  return (
    <>
      <div className='intro-action'>
        <p>
          Congratulations! You have completed Module 3. Take a week or two to
          practice what you learned before moving on to Module 4! – here is your
          at-home practice for say what you mean and mean what you say:
        </p>
      </div>
      <div className='question-wrapper'>
        <ol className='summary'>
          <li>
            Continue to spend at least 15 minutes every day in child-centered
            time. This activity can include reading with your child.
          </li>
          <li>
            {!res.data ? (
              <>
                Looks like you haven't picked a list of household rules yet!{' '}
                <Link
                  to={'/course/module3/timeline/do-statements/practice1'}
                  onClick={() => {
                    context.setCurSectionIndex(2);
                    context.setCurModLink(
                      '/course/module3/timeline/do-statements/practice1'
                    );
                    context.setCurSlide(3);
                  }}
                >
                  Click here to make one!
                </Link>
              </>
            ) : (
              <>
                Review the list of household rules you made.
                <ul>
                  {res.data.map((item) => (
                    <li style={{ color: '#333b70' }}>{item}</li>
                  ))}
                </ul>
                Reduce the number of instructions and commands to those things
                that are on your list of Household Rules.
              </>
            )}
          </li>
          <li>
            Use do statements and warning statements. Make sure you keep it
            simple and clear!
          </li>
        </ol>
      </div>
      <p className='padding-1 note'>
        <b>NOTE ABOUT PRACTICE:</b> When you are making your plan to complete
        the practice, think about things that might get in the way of using do
        and warning statements and reducing your instructions to those that are
        really important. Try to come up with a plan to overcome these barriers.
      </p>
    </>
  );
};

export default SummaryThree;
