import React, { useState } from 'react';
import '.././PlanIt.scss';
import AddableTextInput from '../../Form/AddableTextInput';
import { handlePracticeSubmit } from '../../core/helperFuncs/handlePracticeSubmit';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
export default function TO1practice() {
  const [misbehavior, setMisbehavior] = useState('');
  const [submitedValue, setSubmitedValue] = useState(false);

  const handleChange = (e) => {
    setRes(false);
    setMisbehavior(e.target.value);
  };
  const [res, setRes] = useGetData('practice-to1');
  const handleSubmit = (e) => {
    e.preventDefault();
    handlePracticeSubmit(e.target.id, misbehavior);
  };
  return (
    <>
      <div>
        <div className='question-wrapper'>
          <p>
            Write one behavior that you want to stop with a time-out (remember,
            these behaviors should be misbehaviors that don’t stop with a
            simple, clear do statement and is specific and clear to the child).
            What is that ?
          </p>
          <form
            id='practice-to1'
            className='flex-wrap'
            onSubmit={(e) => {
              handleSubmit(e);
            }}
          >
            <div className='select-item-wrap'>
              {!submitedValue ? (
                <input
                  type={'text'}
                  className='other-section'
                  value={res.data ? res.data : misbehavior ? misbehavior : ''}
                  onChange={handleChange}
                  placeholder={''}
                ></input>
              ) : (
                <p className='input-value'>{misbehavior}</p>
              )}
            </div>
          </form>
        </div>
        <p className='outro' onClick={() => setSubmitedValue(false)}>
          Continue in this module to learn the steps to using time-outs.
        </p>
      </div>
    </>
  );
}
