import React from "react";
import DropDown from "../../Form/DropDown";
const DropDownWrapper = ({ item, state, handleChange }) => {
  return (
    <>
      <p>{item.question}</p>
      <div className="drop-down span-2">
        <DropDown
          value={state[item.name]}
          handleChange={handleChange}
          name={item.name}
          optionsList={["Choose An Option", ...item.options]}
        />
      </div>
    </>
  );
};

export default DropDownWrapper;
