import React from 'react';
import { Consumer } from '../../context.js';
import './page-content.scss';
import Routes from './Routes.js';
function PageContent() {
  // This is the top level component that contains the rerouting logic
  //  - also it determines which component to render, the Module or the Overview component
  //  - The redirect component redirects from the / which is the root link to forward progress
  //
  return <Consumer>{(item, i) => <Routes key={i} />}</Consumer>;
}

export default PageContent;
