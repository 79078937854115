import React, { useState } from 'react';
import getPDF from '../../../../../assets/helpers/getPDF';
import styled from 'styled-components';
import GenStyles from '../../../GenStyles';

const S = {
  HeaderWrapper: styled.div`
    display: flex;
    align-items: center;
    font-size: 1.25rem;
    font-weight: 600;
    gap: 5px;
  `,
  Header1: styled.h2`
    font-size: 1.5rem;
    color: #272e68;
  `,
  Header2: styled.span`
    margin-top: 0;
    font-size: 1.25rem;
    color: #272e68;
    font-weight: bold;
  `,
  ListItem: styled.li`
    list-style: none;
    margin-top: 0.5rem;
  `,
  Header3: styled.span`
    font-size: 1.1rem;
    font-weight: bold;
    color: #272e68;
    display: block;
    margin-bottom: 0.75rem;
    white-space: nowrap;
  `,
  ContactInfo: styled.span`
    font-size: 1rem;
    font-weight: 400;
    color: black;
  `,
};

const Contact = ({
  userID,
  fullName,
  phone,
  email,
  finishedCourse,
  studyId,
}) => {
  const [pdf, setPdf] = useState(false);

  if (finishedCourse && !pdf) {
    getPDF(studyId).then((res) => setPdf(res));
  }

  return (
    <section>
      <S.HeaderWrapper>
        <S.Header1>User ID :</S.Header1>
        <span>{userID}</span>
      </S.HeaderWrapper>
      <S.Header2>Contact</S.Header2>
      <ul>
        <S.ListItem>
          <S.Header3>
            Name:
            <S.ContactInfo>{' ' + fullName}</S.ContactInfo>
          </S.Header3>
        </S.ListItem>
        <S.ListItem>
          <S.Header3>
            Phone Number:
            <S.ContactInfo>{' ' + phone}</S.ContactInfo>
          </S.Header3>
        </S.ListItem>
        <S.ListItem>
          <S.Header3>
            Email:
            <S.ContactInfo>{' ' + email}</S.ContactInfo>
          </S.Header3>
        </S.ListItem>
      </ul>
      {finishedCourse && (
        <GenStyles.UserDetailButton onClick={() => pdf && window.open(pdf)}>
          Download Certificate
        </GenStyles.UserDetailButton>
      )}
    </section>
  );
};

export default Contact;
