import { createContext, useContext, useState } from 'react';

const HeaderContext = createContext();

function HeaderProvider({ children }) {
  const [jiggle, setJiggle] = useState(false);
  return (
    <HeaderContext.Provider value={{ jiggle, setJiggle }}>
      {children}
    </HeaderContext.Provider>
  );
}

function useHeader() {
  const context = useContext(HeaderContext);
  if (context === undefined) {
    throw new Error('use must be used in HeaderProvider');
  }
  return context;
}

export { HeaderProvider, useHeader };
