import React from 'react';

export const FillIn = ({ question, answer }) => {
  return (
    <>
      {question} <span style={{ textDecoration: 'underline' }}>{answer}</span>
    </>
  );
};

const practiceProps = [
  {
    question: 'Spend at least 15 minutes of child-centered time every day:',
    name: 'practiceRev3-1',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'I was able to spend child-centered time with my child:',
    name: 'practiceRev3-2',
    type: 'dropdown',
    options: ['Everyday', 'Almost every day', 'A few days', 'Not at all'],
  },
  {
    question: 'What were some of the child centered activities that you did?',
    name: 'practiceRev3-3',
    type: 'dropdown',
    options: [
      'Read',
      'Played the child’s choice of games',
      'Danced',
      'Sang',
      'Other',
    ],
  },
  {
    question: 'Use do statements and warning statements.',
    name: 'practiceRev3-4',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question:
      'Reduce the number of instructions to those that were really important.',
    name: 'practiceRev3-5',
    type: 'radio',
    options: ['YES', 'NO'],
  },
];

export default practiceProps;
