import React, { useContext, useEffect, useState } from 'react';
import './bagOfTricksPage.scss';
import { Context } from '../../../context';
import { ReactComponent as BagOfTricksImage } from '../../../assets/images/header-icons/tricks.svg';
import Modal from '../Modal/Modal';

function BagOfTricksPage(props) {
  const context = useContext(Context);
  const [show, setShowModal] = useState(false);
  const [currentTrick, setCurrentTrick] = useState({});
  const [trickIsInBag, setTrickIsInBag] = useState(false);

  useEffect(() => {
    context.setSidebarModuleOpen(0);
  }, []);

  const findTheInactiveTrickAndUpdateAllBagOfTricks = (obj, comparedTitle) => {
    Object.entries(obj).map(([entry, value]) => {
      value.map((trick) => {
        if (trick.title === comparedTitle) {
          trick['active'] = false;
        } else {
          trick = { ...trick };
        }
      });
    });
    return obj;
  };

  const deleteTrickPage = async (trickTitle) => {
    let check;
    check = await context.setBagOfTricksContext(
      context.state.bagOfTricks.filter((trick) => trick.title !== trickTitle),
      trickTitle,
      'DELETE'
    );
    if (check) {
      const newAllBagOfTricks = findTheInactiveTrickAndUpdateAllBagOfTricks(
        context.state.allBagOfTricks,
        trickTitle
      );
      context.setActiveForAllBagOfTricksContext(newAllBagOfTricks);
    }
  };

  const findTheActiveTrickAndUpdateAllBagOfTricks = (obj, comparedContent) => {
    Object.entries(obj).map(([entry, value]) => {
      value.map((trick) => {
        if (trick.title === comparedContent.title) {
          trick['active'] = true;
        } else {
          trick = { ...trick };
        }
      });
    });
    return obj;
  };

  const saveTrick = async (content, yesOrNo) => {
    const checkIndex = context.state.bagOfTricks.findIndex(
      (titleInArray) => titleInArray.title == content.title
    );
    if (content && checkIndex === -1 && yesOrNo === 'yes') {
      let check;
      check = await context.setBagOfTricksContext(
        [...context.state.bagOfTricks, content],
        content.title,
        'POST'
      );
      setShowModal((prev) => (prev = false));
      if (check) {
        const newAllBagOfTricks = findTheActiveTrickAndUpdateAllBagOfTricks(
          context.state.allBagOfTricks,
          content
        );
        context.setActiveForAllBagOfTricksContext(newAllBagOfTricks);
      }
    } else if (content && checkIndex !== -1 && yesOrNo === 'yes') {
      setTrickIsInBag(true);
      setShowModal((prev) => (prev = true));
    } else if (yesOrNo === 'no') {
      setTrickIsInBag(false);
      setShowModal((prev) => (prev = false));
    }
  };
  return (
    <div id={'fade'} className={'bot-wrap'}>
      <div className='bag-of-tricks-page'>
        <h1>Bag of Tricks</h1>
        {
          <div className='all-module-container middle-wrapper'>
            {Object.keys(context.state.allBagOfTricks).map(
              (keyName, outterIndex) => {
                if (6 - context.state.disabledArray.length >= outterIndex + 1) {
                  return (
                    <div key={outterIndex} className='card'>
                      <h3 className='module-title'>Module {keyName}</h3>
                      <div className='tricks-container'>
                        {context.state.allBagOfTricks[keyName].map(
                          (trick, index) => {
                            return (
                              <div
                                className={`trick-button-wrapper ${
                                  trick.active ? 'active' : ''
                                }`}
                                onClick={() => {
                                  setShowModal((prev) => !prev);
                                  setCurrentTrick(() => trick);
                                }}
                                key={index}
                              >
                                <span>{trick.title}</span>
                                {trick.active && (
                                  <button
                                    className={`trick-delete ${
                                      trick.active ? 'show' : ''
                                    }`}
                                    onClick={(e) => {
                                      deleteTrickPage(trick.title);
                                      e.stopPropagation();
                                    }}
                                  >
                                    <svg
                                      fill='#000000'
                                      height='16px'
                                      width='16px'
                                      version='1.1'
                                      id='Capa_1'
                                      viewBox='0 0 460.775 460.775'
                                    >
                                      <path
                                        d='M285.08,230.397L456.218,59.27c6.076-6.077,6.076-15.911,0-21.986L423.511,4.565c-2.913-2.911-6.866-4.55-10.992-4.55
	c-4.127,0-8.08,1.639-10.993,4.55l-171.138,171.14L59.25,4.565c-2.913-2.911-6.866-4.55-10.993-4.55
	c-4.126,0-8.08,1.639-10.992,4.55L4.558,37.284c-6.077,6.075-6.077,15.909,0,21.986l171.138,171.128L4.575,401.505
	c-6.074,6.077-6.074,15.911,0,21.986l32.709,32.719c2.911,2.911,6.865,4.55,10.992,4.55c4.127,0,8.08-1.639,10.994-4.55
	l171.117-171.12l171.118,171.12c2.913,2.911,6.866,4.55,10.993,4.55c4.128,0,8.081-1.639,10.992-4.55l32.709-32.719
	c6.074-6.075,6.074-15.909,0-21.986L285.08,230.397z'
                                      />
                                    </svg>
                                  </button>
                                )}
                              </div>
                            );
                          }
                        )}
                      </div>
                    </div>
                  );
                }
              }
            )}
          </div>
        }
        {/* {
          <div className="all-module-container">
              <div>
                <h3 className="module-title">Module</h3>
                <div className="tricks-container">
                  {
                    context.state.allBagOfTricks.map((trick, index) => {
                      {
                        return (
                          <span className={trick.active ? 'active' : ''} key={index}>{trick.title}</span>
                        )
                      }
                    })
                  }
                </div>
              </div>
          </div>
        } */}
        {/* <ul>
          { context.state.bagOfTricks.length > 0 ?
            (
              context.state.bagOfTricks.map((trick, index) => {
                return (
                <li key={index}>
                  <span className="trick-title">{trick.title}</span><br/>
                  <span className="trick-body">{trick.body}</span>
                  <button className="trick-delete" onClick={() => deleteTrickPage(trick.title)}>X</button>
                </li>
                )
              })
            ) : <li>No Contents</li>
          }
        </ul> */}
      </div>
      <Modal show={show} showModal={setShowModal}>
        <div className='trick-wrapper'>
          <div className='top-wrapper'>
            <BagOfTricksImage />
            <h4>A New Trick!</h4>
          </div>
          <p className='trick-body'>
            <b>{currentTrick.title} : </b>
            {currentTrick.body}
          </p>
          {currentTrick.active === true ? (
            ''
          ) : (
            <div>
              <p className='trick-question'>
                Would you like to add this to your Bag of Tricks?
              </p>
              <div className='trick-button'>
                <button
                  disabled={trickIsInBag}
                  onClick={() => saveTrick(currentTrick, 'yes')}
                >
                  Yes
                </button>
                <button onClick={() => saveTrick(currentTrick, 'no')}>
                  No
                </button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
}

export default BagOfTricksPage;
