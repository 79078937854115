import React, { useState } from 'react';

const TextInput = ({
  handleChange,
  state,
  name,
  placeholder,
  type,
  initial,
}) => {
  return (
    <input
      name={name}
      placeholder={initial ? initial : ''}
      type={type}
      onChange={(e) => {
        handleChange(e);
      }}
      value={state || ''}
    ></input>
  );
};

export default TextInput;
