import React, { useState, useContext, useEffect } from 'react';
import Modal from '../Modal/Modal';
import './tricks.scss';
import { ReactComponent as BagOfTricksImage } from '../../../assets/images/header-icons/tricks.svg';
import { Context } from '../../../context';
import Sound from './tricks-chimes.wav';
import { useHeader } from '../../core/header/headerContext';
export const Tricks = (props) => {
  const { content } = props;
  const [show, setShowModal] = useState(false);
  const context = useContext(Context);
  const [trickIsInBag, setTrickIsInBag] = useState(false);
  const [currentTrick, setCurrentTrick] = useState(content);
  const { setJiggle } = useHeader();
  const something = context.state.bagOfTricks
    .map((trick) => {
      return trick.title;
    })
    .indexOf(currentTrick.title);
  if (something < 0 && currentTrick['active'] === true) {
    content['active'] = false;
    setCurrentTrick(content);
  } else if (something >= 0 && currentTrick['active'] === false) {
    content['active'] = true;
    setCurrentTrick(content);
  } else if (something >= 0 && currentTrick['active'] === undefined) {
    content['active'] = true;
    setCurrentTrick(content);
  }

  const findTheActiveTrickAndUpdateAllBagOfTricks = (obj, comparedContent) => {
    Object.entries(obj).map(([entry, value]) => {
      value.map((trick) => {
        if (trick.title === comparedContent.title) {
          trick['active'] = true;
          setCurrentTrick(trick);
        } else {
          trick = { ...trick };
        }
      });
    });
    return obj;
  };

  const saveTrick = async (content, yesOrNo) => {
    const checkIndex = context.state.bagOfTricks.findIndex(
      (titleInArray) => titleInArray.title == content.title
    );
    if (content && checkIndex === -1 && yesOrNo === 'yes') {
      content['active'] = true;
      let check;
      check = await context.setBagOfTricksContext(
        [...context.state.bagOfTricks, content],
        content.title,
        'POST'
      );
      setShowModal((prev) => (prev = false));
      if (check) {
        const newAllBagOfTricks = findTheActiveTrickAndUpdateAllBagOfTricks(
          context.state.allBagOfTricks,
          content
        );
        context.setActiveForAllBagOfTricksContext(newAllBagOfTricks);
      }
    } else if (content && checkIndex !== -1 && yesOrNo === 'yes') {
      setTrickIsInBag(true);
      setShowModal((prev) => (prev = true));
    } else if (yesOrNo === 'no') {
      setTrickIsInBag(false);
      setShowModal((prev) => (prev = false));
    }
  };

  return (
    <>
      <button
        className={'tricks-button'}
        onClick={() => {
          var audio = new Audio(Sound);
          audio.play();
          setShowModal((prev) => !prev);
        }}
      >
        <span>Bag of Tricks</span>
        <BagOfTricksImage />
      </button>
      <Modal show={show} showModal={setShowModal} tricks>
        <div className='trick-wrapper'>
          <div className='top-wrapper'>
            <BagOfTricksImage />
            <h4>A New Trick!</h4>
          </div>
          <p className='trick-body'>
            <b>{content.title} : </b>
            {content.body}
          </p>
          {currentTrick.active === true ? (
            ''
          ) : (
            <div>
              <p className='trick-question'>
                Would you like to add this to your Bag of Tricks?
              </p>
              <div className='trick-button'>
                <button
                  disabled={trickIsInBag}
                  onClick={() => {
                    saveTrick(content, 'yes');
                    setJiggle(true);
                    setTimeout(() => {
                      setJiggle(false);
                    }, 1000);
                  }}
                >
                  Yes
                </button>
                <button onClick={() => saveTrick(content, 'no')}>No</button>
              </div>
            </div>
          )}
        </div>
      </Modal>
    </>
  );
};
