class LoginServiceClass {
  constructor() {
    this.sessionMinutes = 40;
    this.cookiePrefix = 'template-validate=';
    this.data = {
      userid: sessionStorage.userid,
    };
  }

  login(username) {
    this.data.username = username;
    this.makeCookie();
  }

  isLoggedIn(updateCookie = true) {
    const currentCookie = this.getCookie();

    if (
      (!currentCookie && !sessionStorage.storedCookie) ||
      currentCookie !== sessionStorage.storedCookie
    ) {
      if (currentCookie) {
        this.clearCookie();
      }
      return false;
    } else {
      if (updateCookie) {
        this.makeCookie();
      }
      return true;
    }
  }

  getCookie() {
    const ca = document.cookie.split(';');
    let returnedCookie;
    ca.forEach((cookie) => {
      while (cookie.charAt(0) === ' ') cookie = cookie.substring(1);
      if (cookie.indexOf(this.cookiePrefix) === 0)
        returnedCookie = cookie.substring(
          this.cookiePrefix.length,
          cookie.length
        );
    });
    return returnedCookie;
  }

  makeCookie() {
    const date = new Date();
    const cookieData = date.getTime() + '.' + btoa(this.data.username);
    const maxage = 'max-age=' + this.sessionMinutes * 60; // allow 40 min (this.sessionMinute) per page before session ends
    document.cookie = `${this.cookiePrefix}${cookieData};${maxage};path=/`;

    sessionStorage.storedCookie = cookieData;
  }

  clearCookie() {
    sessionStorage.clear();
    document.cookie = `${this.cookiePrefix};max-age=1;path=/`;
  }
}

const LoginService = new LoginServiceClass();
Object.freeze(LoginService);
export default LoginService;
