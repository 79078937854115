import React, { useState, useContext } from 'react';
import { Context } from '../../../context';
import Modal from '../../shared/Modal/Modal';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
const DevTipItems = ({ notification }) => {
  const [showModule, setShowModal] = useState(false);
  const context = useContext(Context);
  return (
    <>
      <div
        className='tip-wrapper'
        onClick={(e) => {
          e.stopPropagation();
          setShowModal(true);
          context.closeMenu();
        }}
      >
        <p>{notification.title}</p>
        <button
          className='closeNoti'
          onClick={(e) => {
            e.stopPropagation();
            context.checkDevTips(notification.module, notification.id, 'both');
            context.closeMenu();
          }}
        >
          <Close />
        </button>
      </div>

      <Modal
        show={showModule}
        showModal={() => {
          setShowModal(false);
          context.checkDevTips(notification.module, notification.id, 'both');
        }}
        overide
      >
        <div className='devtip-wrap'>
          <div className='head'>
            <div className='icon'></div>
          </div>
          <div
            className='devtip-content'
            dangerouslySetInnerHTML={{ __html: notification.content }}
          ></div>
        </div>
      </Modal>
    </>
  );
};

export default DevTipItems;
