import React from "react";

export const Checkbox = ({
  isChecked,
  label,
  checkHandler,
  index,
}) => {
  return (
    <div className="select-item-wrap" >
      <input
        type="checkbox"
        id={`checkbox-${index}`}
        checked={isChecked}
        onChange={checkHandler}
      />
      <label htmlFor={`checkbox-${index}`}>{label}</label>
    </div>
  );
};
