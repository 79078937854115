import React from 'react';
import GenStyles from '../../../GenStyles';
import styled from 'styled-components';
import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';
const S = {
  Survey: styled.li`
    list-style: none;
    display: flex;
    align-items: center;
    font-size: 1rem;
    margin: 0.75rem 0;
    font-weight: bold;
  `,

  SurveyAnswer: styled.span`
    margin-left: 5px;
    color: ${(props) => props.color};
  `,
};

const rating = [
  { value: 'Very Helpful', color: '#019966' },
  { value: 'Helpful', color: '#fbaf43' },
  { value: 'A Little Helpful', color: '#f05a2d' },
  { value: 'Not Helpful', color: 'red' },
];

const Surveys = ({ surveyAnswers, titleNames }) => {
  const surveys = titleNames.map((item, index) => {
    const selectedMod = surveyAnswers.find((item) => item.module === index + 1);
    let answer = '';
    let color = 'black';
    if (selectedMod) {
      answer = rating[selectedMod.rating - 1].value;
      color = rating[selectedMod.rating - 1].color;
    }
    if (item.date && !selectedMod) {
      answer = 'Not Answered';
    }
    return {
      answer: answer,
      module: index + 1,
      color: color,
    };
  });

  //   console.log(selectedMod);

  //   let answers = false;

  //   if (!selectedMod) {
  //     if (item.date) {
  //       answers = {
  //         color: 'black',
  //         rating: 'Not Answered',
  //       };
  //     }
  //   }

  //   if (selectedMod) {
  //     answers = {
  //       color: rating[selectedMod.rating - 1].color,
  //       rating: rating[selectedMod.rating - 1].value,
  //     };
  //   }

  //   return {
  //     modNum: index + 1,
  //     rating: rating[selectedMod.rating - 1].value,
  //   };
  // });
  // console.log(surveys);

  return (
    <GenStyles.UserItemWrapper>
      <GenStyles.InfoHeader>Module Survey</GenStyles.InfoHeader>
      <ul style={{ padding: 0 }}>
        {surveys.map((item) => {
          return (
            <S.Survey checked>
              Module {item.module} :
              <S.SurveyAnswer color={item.color}>{item.answer}</S.SurveyAnswer>
            </S.Survey>
          );
        })}
      </ul>
    </GenStyles.UserItemWrapper>
  );
};

export default Surveys;
