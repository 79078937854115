import React, { useEffect, useState, useContext } from 'react';
// import "../handouts/handouts.scss";
import './badges.scss';
import allBadges from './allBadges';
import { useBadge } from '../../../customHooks/useBadge';
import ActivityBadge from './ActivityBadge';

function Badge(props) {
  const [{ showBadge }, dispatch] = useBadge(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      dispatch({ type: 'show', payload: props.content });
    }, 500);

    return () => {
      clearTimeout(timeout);
    };
  }, []);

  useEffect(() => {
    let timeout;
    if (showBadge === true) {
      timeout = setTimeout(() => {
        dispatch(false);
      }, 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [showBadge]);

  return (
    <ActivityBadge
      showBadge={showBadge}
      content={props.content}
      dispatch={dispatch}
    />
  );
}

export default Badge;
