import React, { useState, useEffect } from 'react';
import Modal from '../../shared/Modal/Modal';
import './ClickReveal.scss';
import DOMPurify from 'dompurify';
import ActivityBadge from '../../shared/badges/ActivityBadge';
import { useBadge } from '../../../customHooks/useBadge';
import reframing from '../../../assets/course/ClickReveal/reframing.json';
import routines from '../../../assets/course/ClickReveal/routines.json';
import timeOut from '../../../assets/course/ClickReveal/time-out.json';
function ClickReveal(props) {
  const [content, changeContent] = useState(null);
  const [modalContent, changeModalContent] = useState(null);
  const [showModal, toggleModal] = useState(false);
  const [clicked, changeClicked] = useState([]);
  const [{ showBadge }, dispatch] = useBadge(false);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    switch (props.path) {
      case '/reframing':
        changeContent(reframing);
        break;
      case '/routines':
        changeContent(routines);
        break;
      case '/timeOut':
        changeContent(timeOut);
        break;
      default:
        console.log('invalid');
    }
  }, [props.path]);

  // useEffect(() => {
  //   props.setGetHeight('reset me');
  // }, [content]);

  const handleClickReveal = (data, index) => {
    dispatch({ type: 'show', payload: props.badge });
    const mySafeHTML = DOMPurify.sanitize(data.routine);

    let item = {
      value: data.value,
      routine: { __html: mySafeHTML },
    };

    changeClicked([...clicked, index]);
    changeModalContent(item);
    toggleModal(true);
  };

  return (
    <>
      {content ? (
        <>
          <div className='click-content'>
            {content.type === 'block'
              ? // click-reveal content (blocks)
                content.contents.map((item, i) => (
                  <div
                    key={item.value}
                    className={`clickable-reveal-block ${
                      clicked.includes(i) ? 'clicked' : ''
                    }`}
                    onClick={() => handleClickReveal(item, i)}
                  >
                    <p>{item.value}</p>
                  </div>
                ))
              : // click-reveal content (lines)
                content.contents.map((item, i) => (
                  <div
                    key={item.value}
                    className={`clickable-reveal-line ${
                      clicked.includes(i) ? 'clicked' : ''
                    }`}
                    onClick={() => handleClickReveal(item, i)}
                  >
                    {item.value}
                  </div>
                ))}
          </div>
          <ActivityBadge
            showBadge={showBadge}
            content={props.badge}
            dispatch={dispatch}
          />
        </>
      ) : (
        <></>
      )}
      {showModal ? (
        modalContent ? (
          <Modal showModal={toggleModal} show={showModal}>
            <h2>{modalContent.value}</h2>
            <div dangerouslySetInnerHTML={modalContent.routine}></div>
          </Modal>
        ) : (
          <></>
        )
      ) : (
        <></>
      )}
    </>
  );
}

export default ClickReveal;
