import React, { useContext } from 'react';
import { Context } from '../../../context';
// import { NavContext } from "../../utils/NavContext";
import { StyledHamburgerIcon, StyledHamburgerWrapper } from './HamburgerStyles';
const Hamburger = () => {
  const content = useContext(Context);
  return (
    <StyledHamburgerWrapper>
      <StyledHamburgerIcon
        aria-label='Open Nav'
        role='button'
        closed={content.state.isSidebarOpen}
        onClick={content.toggleMenu}
      >
        <div />
      </StyledHamburgerIcon>
    </StyledHamburgerWrapper>
  );
};

export default Hamburger;
