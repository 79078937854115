import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import PracticeChecklist from '../../../../practice-checklist/PracticeChecklist';
import practiceProps1 from '../../../../../assets/course/practice-checklist/module-1';
import practiceProps2 from '../../../../../assets/course/practice-checklist/module-2';
import practiceProps3 from '../../../../../assets/course/practice-checklist/module-3';
import practiceProps4 from '../../../../../assets/course/practice-checklist/module-4';
import practiceProps5 from '../../../../../assets/course/practice-checklist/module-5';
import practiceProps6 from '../../../../../assets/course/practice-checklist/module-6';
import leftArrow from '../../../assets/leftArrow.svg';
import rightArrow from '../../../assets/rightArrow.svg';
import AdminPracticeChecklist from './AdminPracticeChecklist';

const S = {
  SliderWrapper: styled.div`
    overflow-x: hidden;
  `,
  SliderOuterWrapper: styled.div`
    width: 100%;
    position: relative;
    padding: 0 5rem;
    margin: auto;
    box-sizing: border-box;
    position: relative;
  `,
  AllSlides: styled.div`
    display: flex;
  `,
  Slide: styled.div`
    margin: auto;
    white-space: normal;
  `,
  ShrinkSlide: styled.div`
    width: 75%;
    margin: auto;
  `,
  ArrowWrapper: styled.img`
    height: 80px;
    cursor: pointer;
    /* ${(props) =>
      props.left &&
      css`
        left: 0;
      `}
    ${(props) =>
      props.right &&
      css`
        right: 0;
      `} */
    width: auto;
    top: 200px;
    &:hover {
      transform: scale(0.9);
      transition: 0.2s;
    }
  `,
  UserItemWrapper: styled.div`
    flex: 0;
    width: 100%;
    flex: 1;
    white-space: normal;
    flex-basis: 1000px;
    width: 100%;
    position: relative;
  `,

  CenterHeader: styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
  `,
  InfoHeader: styled.span`
    display: block;
    font-size: 1.4rem;
    color: #5f2c86;
    font-weight: bold;
    margin: 1rem 0;
    & > span {
      color: black;
    }
  `,
};

const Styles = {
  SlidesStyled: styled.div`
    max-width: 750px;
    margin: auto;
    @media (min-width: 1000px) {
      width: 750px;
    }
  `,
};

const Slider = ({ slides, practiceAssignment }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const goPrev = () => {
    currentIndex === 0
      ? setCurrentIndex(slides.length - 1)
      : setCurrentIndex((prev) => prev - 1);
  };

  const goNext = () => {
    slides.length - 1 === currentIndex
      ? setCurrentIndex(0)
      : setCurrentIndex((prev) => prev + 1);
  };

  return (
    <S.UserItemWrapper>
      <S.CenterHeader>
        <S.ArrowWrapper src={leftArrow} onClick={goPrev} />
        <S.InfoHeader>
          {`Practice ${practiceAssignment ? 'Assignment' : 'Reviews'} ${
            slides.length
          }/6 completed`}
        </S.InfoHeader>
        <S.ArrowWrapper src={rightArrow} onClick={goNext} />
      </S.CenterHeader>
      <Styles.SlidesStyled>{slides[currentIndex]}</Styles.SlidesStyled>
    </S.UserItemWrapper>
  );
};

export default Slider;
