import React from 'react';

export const FillIn = ({ question, answer }) => {
  return (
    <>
      {question} <span style={{ textDecoration: 'underline' }}>{answer}</span>
    </>
  );
};

const practiceProps = [
  {
    question: 'Spend at least 15 minutes of child-centered time every day:',
    name: 'practiceRev4-1',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'I was able to spend child-centered time with my child:',
    name: 'practiceRev4-2',
    type: 'dropdown',
    options: ['Everyday', 'Almost every day', 'A few days', 'Not at all'],
  },
  {
    question: 'What were some of the child centered activities that you did?',
    name: 'practiceRev4-3',
    type: 'dropdown',
    options: [
      'Read',
      'Played the child’s choice of games',
      'Danced',
      'Sang',
      'Other',
    ],
  },
  {
    question: 'Give at least one natural if/then statement.  ',
    name: 'practiceRev4-4',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'Give at least one logical if/then statement.',
    name: 'practiceRev4-5',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'Give at least one when/then statement.  ',
    name: 'practiceRev4-6',
    type: 'radio',
    options: ['YES', 'NO'],
  },
];

export default practiceProps;
