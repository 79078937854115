import React, {useContext} from "react";
import { NavLink } from "react-router-dom";
import { Context } from "../../../context";
import "./progress-bar.scss";

function ProgressBar(props) {
  const context = useContext(Context);
  return (
    <>
      <div className="progressbar-wrap">
        {props.links.map((item, i) => {
          return (
            <div key = {i}>
              <NavLink
                to={item.url}
                className={`circle-link ${context.state.completedArray.includes(item.url) ? 'completed' : ""}`}
                title={item.title}
                activeClassName="circle-selected"
              ></NavLink>
              {i < props.links.length - 1 ? (
                <div className="line"></div>
              ) : (
                <></>
              )}
            </div>
          );
        })}
      </div>
    </>
  );
}

export default ProgressBar;
