import React, { useEffect, useState, useContext } from 'react';
import styled from 'styled-components';
import GenStyles from '../../GenStyles';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import Slider from './componenets/Slider';
import Contact from './componenets/Contact';
import Logins from './componenets/Logins';
import ModuleStatus from './componenets/ModuleStatus';
import Badges from './componenets/Badges';
import { useAdminInfo } from '../../adminContext';
import { Redirect } from 'react-router-dom/cjs/react-router-dom.min';
import Surveys from './componenets/Surveys';
import useFetch from '../../customHooks/useFetch';
import format from 'date-fns/format';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import parseISO from 'date-fns/parseISO';
import { useParams } from 'react-router-dom/cjs/react-router-dom.min';
import PracticeAssignment from './componenets/PracticeAssignment';
import PracticeReviews from './componenets/PracticeReviews';
import { Context } from '../../../../context';
import { Slide } from 'react-toastify';
const S = {
  PageWrapper: styled.div`
    text-align: left;
    max-width: 1000px;
    margin: auto;
    white-space: nowrap;
  `,
  UserInfo: styled.div`
    border-top: #378abd solid 2px;
    margin-top: 1.5rem;
    display: flex;
    flex-wrap: wrap;
    column-gap: 4rem;
  `,
  DeleteButton: styled.button`
    display: inherit;
    margin-left: auto;
    padding: 0.5rem 0.75rem;
    background: red;
    border: red solid 2px;
    border-radius: 5px;
    font-size: 1rem;
    color: white;
    font-weight: bold;
    text-decoration: none;
    &:hover {
      background: white;
      color: #5f2c86;
    }
  `,
};

const UserDetailPage = () => {
  const [redirect, setRedirect] = useState(false);
  const { res, callAPI } = useAdminInfo();
  const context = useContext(Context);
  const history = useHistory();

  const { id } = useParams();
  const [state, callDetailApi] = useFetch();
  useEffect(() => {
    if (res.isLoading) {
      history.push('/admin');
    }
    callDetailApi({ method: 'GET', url: `admin/user/${id}` });
  }, [callDetailApi, history, id, res]);

  const program = context.state.isAdmin.program;

  const overviewItems = res.data.find((item) => item.id == id);

  const hekModules = [
    {
      title: 'Module 1: Common Childhood Illnesses',
      badgeName: 'badge13',
    },
    {
      title: 'Module 2: Childhood Immunizations',
      badgeName: 'badge23',
    },
    {
      title: 'Module 3: Safety: In the Home',
      badgeName: 'badge33',
    },
    {
      title: 'Module 4: Nutrition: Ready, Set, Grow!',
      badgeName: 'badge43',
    },
    {
      title: "Module 5: Oral Health: Protecting Your Child's Teeth",
      badgeName: 'badge53',
    },
    {
      title: 'Module 6: Safety: Out & About',
      badgeName: 'badge63',
    },
  ];

  const ezpModules = [
    {
      title: 'Module 1: Child-Centered Time, Routines, & Traditions',
      badgeName: 'badge12',
    },
    {
      title: 'Module 2: Using Praise, Encouragement, and Rewards',
      badgeName: 'badge25',
    },
    {
      title: 'Module 3: Importance of Household Rules',
      badgeName: 'badge33',
    },
    {
      title: 'Module 4: Setting Limits and Managing Behavior',
      badgeName: 'badge45',
    },
    {
      title: 'Module 5: Effective Discipline and Time-Outs',
      badgeName: 'badge54',
    },
    {
      title: 'Module 6: Reducing Stress and Problem Solving',
      badgeName: 'badge65',
    },
  ];

  let titleNames;
  let finishedCourse;
  if (!state.isLoading) {
    titleNames = (program === 'EZP' ? ezpModules : hekModules).map((item) => {
      const completedItem = state.data.badges.find(
        (modItem) => item.badgeName === modItem.badgename
      );
      return completedItem
        ? { ...item, date: completedItem.date }
        : { ...item, date: undefined };
    });
    finishedCourse = titleNames.every((item) => item.date !== undefined);
  }

  return redirect ? (
    <Redirect to={'/admin'} />
  ) : (
    <>
      {res.isLoading || (state.isLoading && <div>...loading</div>)}
      {res.data && state.data && (
        <S.PageWrapper>
          <div>
            <Link to={'/admin'}>&lt;&lt;Back</Link>
            <S.DeleteButton
              onClick={() => {
                callAPI({ method: 'DELETE', url: `Admin/User/${id}` }).then(
                  () => {
                    setRedirect(true);
                  }
                );
              }}
            >
              Remove User
            </S.DeleteButton>
          </div>
          <Contact
            userID={overviewItems.studyid}
            fullName={state.data.fullname}
            phone={state.data.phone}
            email={state.data.email}
            completedCourse={true}
            studyId={id}
            finishedCourse={finishedCourse}
          />
          <S.UserInfo>
            <Logins loginArray={state.data.logins} />
            <ModuleStatus titleNames={titleNames} program={program} />
            <Surveys
              titleNames={titleNames}
              surveyAnswers={state.data.moduleSurveys}
            />
            <Badges
              completedBadges={state.data.badges.map((item) => ({
                ...item,
                date: format(parseISO(item.date), 'LL/dd/y'),
              }))}
              program={program}
            />
            {program === 'EZP' && (
              <>
                <PracticeAssignment
                  practiceItems={state.data.practices}
                  titleNames={titleNames}
                />
                <PracticeReviews
                  practiceReviewArray={state.data.practiceReviews}
                  practiceItems={state.data.practices}
                />
              </>
            )}
          </S.UserInfo>
        </S.PageWrapper>
      )}
      {!res.data &&
        !state.data &&
        !res.data.isLoading &&
        !state.data.isLoading && (
          <div>
            {' '}
            <Link to={'/admin'}>&lt;&lt;Back</Link>
            <p>Error Loading Participants Data</p>
          </div>
        )}
    </>
  );
};

export default UserDetailPage;
