import React, { useState } from 'react';
import '../practice/PlanIt.scss';
import './activity.scss';
import CheckListActivity from './CheckListActivity';
import TimeOutActivity from '../activities/TimeoutActivity';
import StatementMatch from './StatementMatch';

const renderActivity = (content, badge, identifier, submitID) => {
  switch (content.type) {
    case 'checklist':
      return (
        <div className='activity-wrap'>
          <div className='routine activity-wrapper'>
            <CheckListActivity
              badge={badge}
              content={content}
              identifier={identifier}
              submitID={submitID}
            />
          </div>
        </div>
      );

    case 'statementMatch':
      return (
        <div className='activity-wrap'>
          <div className='routine activity-wrapper'>
            <StatementMatch badge={badge} content={content} />
          </div>
        </div>
      );
    case 'iconPop':
      return <TimeOutActivity badge={badge} content={content} />;
    default:
      return <></>;
  }
};

const Activity = (props) => {
  const { content, identifier, badge, submitID } = props;
  return <>{renderActivity(content, badge, identifier, submitID)}</>;
};

export default Activity;
