import { Context } from '../context';
import { useContext } from 'react';
import { useReducer } from 'react';

export const handleBadgeSubmit = async (payload, logoutFunc) => {
  if (payload) {
    payload = JSON.stringify(payload);
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + `/api/badge`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: payload,
      });
      if (data.ok) {
        let response;
      } else {
        if (data.status === 401) {
          logoutFunc();
        }
        const errorMessage = await data.text();
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  }
};

export const useBadge = (defValue) => {
  const context = useContext(Context);

  const reducer = (state, action) => {
    switch (action.type) {
      case 'show':
        if (!state.badgeCompleted) {
          handleBadgeSubmit(action.payload, context.logout);
          return { ...state, showBadge: true };
        }
        return state;
      case 'hide':
        return { ...state, showBadge: false, badgeCompleted: true };
      case 'toggle':
        return { ...state, showBadge: !state.showBadge };
      default:
        return state;
    }
  };

  const [{ showBadge, badgeCompleted }, dispatch] = useReducer(reducer, {
    showBadge: defValue,
    badgeCompleted: defValue,
  });
  return [{ showBadge, badgeCompleted }, dispatch];
};
