// allBadges["badge11"] = require("../../../assets/badges/badge11.png").default;
// allBadges["badge12"] = require("../../../assets/badges/badge12.png").default;
// allBadges["badge21"] = require("../../../assets/badges/badge21.png").default;
// allBadges["badge22"] = require("../../../assets/badges/badge22.png").default;
// allBadges["badge23"] = require("../../../assets/badges/badge23.png").default;
// allBadges["badge24"] = require("../../../assets/badges/badge24.png").default;
// allBadges["badge25"] = require("../../../assets/badges/badge25.png").default;
// allBadges["badge31"] = require("../../../assets/badges/badge31.png").default;
// allBadges["badge32"] = require("../../../assets/badges/badge32.png").default;
// allBadges["badge33"] = require("../../../assets/badges/badge33.png").default;
// allBadges["badge41"] = require("../../../assets/badges/badge41.png").default;
// allBadges["badge42"] = require("../../../assets/badges/badge42.png").default;
// allBadges["badge43"] = require("../../../assets/badges/badge43.png").default;
// allBadges["badge44"] = require("../../../assets/badges/badge44.png").default;
// allBadges["badge45"] = require("../../../assets/badges/badge45.png").default;
// allBadges["badge51"] = require("../../../assets/badges/badge51.png").default;
// allBadges["badge52"] = require("../../../assets/badges/badge52.png").default;
// allBadges["badge53"] = require("../../../assets/badges/badge53.png").default;
// allBadges["badge54"] = require("../../../assets/badges/badge54.png").default;
// allBadges["badge61"] = require("../../../assets/badges/badge61.png").default;
// allBadges["badge62"] = require("../../../assets/badges/badge62.png").default;
// allBadges["badge63"] = require("../../../assets/badges/badge63.png").default;
// allBadges["badge64"] = require("../../../assets/badges/badge64.png").default;
// allBadges["badge65"] = require("../../../assets/badges/badge65.png").default;
// allBadges["badge23"] =
//   require("../../../assets/badges/badge_ACT_PDA.png").default;
import flow from '../../../assets/flow.json';

const badges = [
  {
    badgeName: 'badge11',
    urlUsed: '/course/module1/timeline/routines-traditions/slide6',
    type: 'activity',
    checked: false,
    module: 1,
    slideIndex: 6,
  },
  {
    badgeName: 'badge12',
    urlUsed: '/course/module1/timeline/summary-mod1/summary',
    type: 'module',
    checked: false,
    module: 1,
    slideIndex: 1,
  },
  {
    badgeName: 'badge21',
    urlUsed: '/course/module2/timeline/review1/intro',
    type: 'practice',
    checked: false,
    module: 2,
    slideIndex: 0,
  },
  {
    badgeName: 'badge22',
    urlUsed: '/course/module2/timeline/labeled-praise/activity1',
    type: 'activity',
    checked: false,
    module: 2,
    slideIndex: 1,
  },
  {
    badgeName: 'badge23',
    urlUsed: '/course/module2/timeline/labeled-praise/activity2',
    type: 'activity',
    checked: false,
    module: 2,
    slideIndex: 7,
  },
  {
    badgeName: 'badge24',
    urlUsed: '/course/module2/timeline/reward-behavior/activity1',
    type: 'activity',
    checked: false,
    module: 2,
    slideIndex: 1,
  },
  {
    badgeName: 'badge25',
    urlUsed: '/course/module2/timeline/summary-mod2/summary',
    type: 'module',
    checked: false,
    module: 2,
    slideIndex: 1,
  },
  {
    badgeName: 'badge31',
    urlUsed: '/course/module3/timeline/review2/intro',
    type: 'practice',
    checked: false,
    module: 3,
    slideIndex: 0,
  },
  {
    badgeName: 'badge32',
    urlUsed: '/course/module3/timeline/do-statements/ds-activity',
    type: 'activity',
    checked: false,
    module: 3,
    slideIndex: 11,
  },
  {
    badgeName: 'badge33',
    urlUsed: '/course/module3/timeline/summary-mod3/summary',
    type: 'module',
    checked: false,
    module: 3,
    slideIndex: 1,
  },
  {
    badgeName: 'badge41',
    urlUsed: '/course/module4/timeline/review3/intro',
    type: 'practice',
    checked: false,
    module: 4,
    slideIndex: 0,
  },
  {
    badgeName: 'badge42',
    urlUsed: '/course/module4/timeline/natural-if/if-activity',
    type: 'activity',
    checked: false,
    module: 4,
    slideIndex: 3,
  },
  {
    badgeName: 'badge43',
    urlUsed: '/course/module4/timeline/logical-if/log-activity',
    type: 'activity',
    checked: false,
    module: 4,
    slideIndex: 4,
  },
  {
    badgeName: 'wizard',
    urlUsed: '/course/module4/timeline/when-then/wt-activity',
    type: 'activity',
    checked: false,
    module: 4,
    slideIndex: 2,
  },
  {
    badgeName: 'badge44',
    urlUsed: '/course/module4/timeline/ignore/ig-activity',
    type: 'activity',
    module: 4,
    slideIndex: 1,
  },
  {
    badgeName: 'badge45',
    urlUsed: '/course/module4/timeline/summary-mod4/summary',
    type: 'module',
    checked: false,
    module: 4,
    slideIndex: 1,
  },
  {
    badgeName: 'badge51',
    urlUsed: '/course/module5/timeline/review4/intro',
    type: 'practice',
    checked: false,
    module: 5,
    slideIndex: 0,
  },
  {
    badgeName: 'badge52',
    urlUsed: '/course/module5/timeline/time-out3/to-activity',
    type: 'activity',
    checked: false,
    module: 5,
    slideIndex: 2,
  },
  {
    badgeName: 'badge53',
    urlUsed: '/course/module5/timeline/time-public/tp-activity',
    type: 'activity',
    module: 5,
    slideIndex: 2,
  },
  {
    badgeName: 'badge54',
    urlUsed: '/course/module5/timeline/summary-mod5/summary',
    type: 'module',
    module: 5,
    slideIndex: 1,
  },
  {
    badgeName: 'badge61',
    urlUsed: '/course/module6/timeline/review5/intro',
    type: 'practice',
    module: 6,
    slideIndex: 0,
  },
  {
    badgeName: 'badge62',
    urlUsed: '/course/module6/timeline/parent-stress/ps-activity',
    type: 'activity',
    module: 6,
    slideIndex: 1,
  },
  {
    badgeName: 'badge63',
    urlUsed: '/course/module6/timeline/reducing-stress/reframing-activity',
    type: 'activity',
    module: 6,
    slideIndex: 6,
  },
  {
    badgeName: 'badge64',
    urlUsed: '/course/module6/timeline/problem/ps1-activity',
    type: 'activity',
    module: 6,
    slideIndex: 3,
  },
  {
    badgeName: 'badge65',
    urlUsed: '/course/module6/timeline/summary-mod6/summary',
    type: 'module',
    module: 6,
    slideIndex: 1,
  },
  {
    badgeName: 'badge66',
    urlUsed: '/course/module6/timeline/practice-review6',
    type: 'practice',
    module: 6,
    slideIndex: 0,
  },
];

const makeBadge = (badgeList) => {
  const handleBadgeGet = async () => {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + `/api/badge`, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (data.ok) {
        let response = await data.json();
        // setCompletedBadges(response);
      } else {
        const errorMessage = await data.text();
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  };

  const findSectionIndex = (module, url) => {
    return flow[module].links.findIndex((item) => {
      return url.includes(item.url);
    });
  };

  return badgeList.map((badge) => ({
    ...badge,
    checked: false,
    section: findSectionIndex(badge.module, badge.urlUsed),
  }));
};

const allBadges = makeBadge(badges);

export default allBadges;
