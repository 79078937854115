export const findPracticeAssignmentAnswer = (practiceItems, keyName) => {
  const answer = findPracticeAnswer(practiceItems, keyName);
  if (answer) {
    return <span style={{ background: 'yellow' }}>{answer}</span>;
  } else {
    return <span style={{ color: 'red' }}>Not Answered</span>;
  }
};

export const findPracticeAnswer = (practiceItems, keyName) => {
  let item = practiceItems.find((item) => item.name === keyName);
  if (item) {
    item = JSON.parse(item.value);
    if (Array.isArray(item)) {
      return item.join(', ');
    }
    return item;
  } else {
    return '';
  }
};
