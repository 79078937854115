import React, { useEffect, useState } from "react";
import "./static-content.scss";

function Static(props) {
  const [content, setContent] = useState(null);
  useEffect(() => {
    setContent({ __html: props.data });
  }, [props.data]);

  return (
    <>
      <div className="static">
        {content ? <div dangerouslySetInnerHTML={content}></div> : <></>}
      </div>
    </>
  );
}

export default Static;
