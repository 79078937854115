import React from "react";
import RadioInput from "../../Form/RadioInput";

const RadioInputWrapper = ({ item, handleChange, state }) => {
  return (
    <>
      <p>{item.question}</p>
      {item.options.map((option, index) => (
        <RadioInput
          state={state}
          handleChange={handleChange}
          key={index}
          name={item.name}
          value={option}
          type={item.type}
        />
      ))}
    </>
  );
};

export default RadioInputWrapper;
