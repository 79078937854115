import React, { useEffect, useContext } from 'react';
import './PlanIt.scss';
import { PracticeWrapper } from './PracticeWrapper';
import IgPractice from './pages/IgPractice';
import DSPracticeOne from './pages/DSPracticeOne';
import RRPPracticeOne from './pages/RRPracticeOne';
import TO1practice from './pages/TO1practice';
import BehaviorAgain from './pages/BehaviorAgain';
import useContent from '../../customHooks/useContent';
import SummaryOne from './pages/SummaryOne';
import SummaryTwo from './pages/SummaryTwo';
import SummaryThree from './pages/SummaryThree';
import SummaryFour from './pages/SummaryFour';
import SummaryFive from './pages/SummaryFive';
import SummarySix from './pages/SummarySix';
import StaticPractice from './pages/StaticPractice';
import { Context } from '../../context';
export default function PlanIt(props) {
  let [content] = useContent(props.path);

  const context = useContext(Context);
  const renderSwitch = (param, content, submitID) => {
    switch (param) {
      case 'RRPracticeOne':
        return (
          <RRPPracticeOne
            submitID={submitID}
            content={content}
          ></RRPPracticeOne>
        );
      case 'DSPracticeOne':
        return <DSPracticeOne content={content}></DSPracticeOne>;
      case 'IgPractice':
        return <IgPractice badge={props.badge} />;
      case 'to1-practice':
        return <TO1practice />;
      case 'summary1':
        return <SummaryOne />;
      case 'summary2':
        return <SummaryTwo />;
      case 'summary3':
        return <SummaryThree />;
      case 'summary4':
        return (
          <SummaryFour
            selectedIgnoreOptionsContext={
              context.state.selectedIgnoreOptionsContext
            }
          />
        );
      case 'summary5':
        return <SummaryFive />;
      case 'summary6':
        return <SummarySix />;
      case 'BehaviorAgain':
        return <BehaviorAgain />;
      default:
        return <StaticPractice content={props.content.bottom} />;
    }
  };

  return (
    <PracticeWrapper content={content}>
      {!props.identifier.includes('summary') && (
        <p className='intro-action'>
          <b>Put what you have learned into action!</b>
        </p>
      )}
      {renderSwitch(props.identifier, content, props.submitID)}
    </PracticeWrapper>
  );
}
