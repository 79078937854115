import React from 'react';
import '.././PlanIt.scss';
import Question from '../Question';
import { CheckList } from '../../Form/CheckList';
export default function DSPracticeOne({ content }) {
  return (
    <>
      <div className='padding-1'>
        <Question>
          1. Think about some of the rules in your home that are really
          important to you. Try to state the rule to include what behavior you
          want to see. Remember, parents value different household rules, so the
          rules will not be the same for everyone.
        </Question>
      </div>
      <div className='question-wrapper'>
        <p className='item-2'>Make a list of up to your top 5 rules.</p>
        <CheckList
          fillIn={content.fillIn}
          options={content.select}
          submitID='ds-practice-1'
          max={5}
        ></CheckList>
      </div>
      <p className='padding-1'>
        2. Try to reduce the number of instructions and commands to those things
        that are on your list of Household Rules.
      </p>
    </>
  );
}
