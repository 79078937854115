import React, { useEffect, useState, useContext, useReducer } from 'react';
import { ServerStyleSheet } from 'styled-components';
import { Context } from '../../../context';
import Modal from '../Modal/Modal';
import './quiz.scss';
import Response from './response';
import { handleKnowledgeCheckPost } from '../../core/helperFuncs/handleKnowledgeCheckPost';
import CorrectSound from './correct-ding.wav';
import WrongSound from './wrong-buzz.wav';
import { handleSubmit } from '../../core/helperFuncs/handleSubmit';

function Quiz(props) {
  let context = useContext(Context);
  let Content = import(`../../../assets/course/KnowledgeCheck${props.path}`);
  const [quizContent, changeContent] = useState(null);
  const [answerObject, changeAnswerObject] = useState(null);
  const [activeResponses, setActiveResponses] = useState([]);

  const [state, setState] = useState([]);
  const [firstClicked, setFirstClicked] = useState([]);
  useEffect(() => {
    Content.then((data) => {
      changeContent(data);
      let hold = [];
      data.default.forEach((item, i) => {
        setFirstClicked((prev) => [...prev, undefined]);
        setState((prev) => [
          ...prev,
          {
            question: item.question,
            solutions: item.answers,
            answer: 'unanswered',
          },
        ]);
        hold.push({ answer: '', correct: false, response: '' });
      });
      changeAnswerObject(hold);
    });
  }, [props.path]);

  const onChangeValue = (event, index, answerIndex) => {
    if (state[index].answer === 'unanswered') {
      setState((prev) =>
        prev.map((item, i) => {
          if (index === i) {
            return { ...item, answer: item.solutions[answerIndex].answer };
          }
          return item;
        })
      );
    }
    let hold = answerObject;
    hold[index] = event;
    changeAnswerObject(hold);
    setActiveResponses([...activeResponses, index]);
  };

  return (
    <>
      <form
        id={props.submitID}
        className='quiz-wrap'
        onSubmit={(e) => {
          e.preventDefault();
          if (!firstClicked.every((item) => item === undefined)) {
            handleKnowledgeCheckPost(props.submitID, firstClicked);
          }
        }}
      >
        {state ? (
          <>
            <div className='question-wrap'>
              <h4 className='question-info'>
                Please read the question
                {state.length > 1 ? 's' : ''} and choose <u>the best answer</u>.
              </h4>
              {state.map((item, index) => {
                return (
                  <div key={index} className='item-wrap'>
                    <div className='question'>{item.question}</div>
                    <>
                      {activeResponses.includes(index) ? (
                        <>
                          <Response answerObject={answerObject} index={index} />
                        </>
                      ) : (
                        <></>
                      )}
                    </>

                    <div>
                      {item.solutions.map((answer, i) => {
                        return (
                          <div className='answers-wrap' key={i}>
                            <input
                              type='radio'
                              name={`group${index}`}
                              onChange={() => onChangeValue(answer, index, i)}
                              onClick={(e) => {
                                const sound = answer.correct
                                  ? CorrectSound
                                  : WrongSound;
                                var audio = new Audio(sound);
                                audio.play();

                                setFirstClicked((prev) =>
                                  prev.map((item, i) =>
                                    index === i && !item ? answer.answer : item
                                  )
                                );
                              }}
                            />
                            <label htmlFor=''>{answer.answer}</label>
                          </div>
                        );
                      })}
                    </div>
                  </div>
                );
              })}
            </div>
          </>
        ) : (
          <></>
        )}
      </form>
    </>
  );
}

export default Quiz;
