import React from 'react';
import TextInput from '../../Form/TextInput';

const TextWrapper = ({ item, handleChange, state }) => {
  return (
    <div className='flex-me'>
      <p>{item.question}</p>
      <TextInput
        item={item}
        state={state['practiceRev2-7']}
        handleChange={handleChange}
        name={item.name}
        placeholder={item.placeholder}
        type={item.type}
      />
    </div>
  );
};

export default TextWrapper;
