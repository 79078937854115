import React, { useState, Fragment } from 'react';
import Modal from '../shared/Modal/Modal';
import HouseIcon from '../../assets/images/time-out-icons/House.png';
import PlaygroundIcon from '../../assets/images/time-out-icons/Playground.png';
import StoreIcon from '../../assets/images/time-out-icons/Store.png';
import RestaurantIcon from '../../assets/images/time-out-icons/Restaurant.png';
import { useBadge } from '../../customHooks/useBadge';
import ActivityBadge from '../shared/badges/ActivityBadge';
import './timeoutActivity.scss';
const icons = {
  house: HouseIcon,
  playground: PlaygroundIcon,
  store: StoreIcon,
  restaurant: RestaurantIcon,
};

const TimeOutActivity = ({ content, badge }) => {
  const [showModals, setShowModals] = useState({});
  const [{ showBadge }, dispatch] = useBadge(false);
  return (
    <>
      <div className='activity-wrap'>
        <div className='routine activity-wrapper'>
          {content.paragraphs.map((item, i) => (
            <p key={i} className='padding-1 item-1'>
              {item}
            </p>
          ))}
          <div className='question-wrapper icon-section'>
            <p>{content.header}</p>
            <div className='icon-wrap'>
              {content.icons.map((item, i) => {
                return (
                  <Fragment key={i}>
                    <div className='icon-wrapper'>
                      <img
                        name={item.header}
                        alt={item.header}
                        src={icons[item.icon]}
                        onClick={(e) => {
                          dispatch({ type: 'show', payload: badge });
                          setShowModals((prevState) => ({
                            ...prevState,
                            [e.target.name]: true,
                          }));
                        }}
                      />
                    </div>
                  </Fragment>
                );
              })}
            </div>
          </div>
        </div>
      </div>
      {content.icons.map((item, i) => {
        return (
          <Modal
            key={i}
            show={showModals[item.header]}
            showModal={(value) =>
              setShowModals((prevState) => ({
                ...prevState,
                [item.header]: value,
              }))
            }
          >
            <div className='devtip-content'>
              <h3>{item.header}</h3>
              <ul>
                {item.body.map((item, i) => (
                  <li key={i}>{item}</li>
                ))}
              </ul>
            </div>
          </Modal>
        );
      })}
      <ActivityBadge
        showBadge={showBadge}
        content={badge}
        dispatch={dispatch}
      />
    </>
  );
};

export default TimeOutActivity;
