import styled, { css } from 'styled-components';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const GenStyles = {
  UserDetailButton: styled.button`
    margin-left: auto;
    padding: 0.5rem 0.75rem;
    background: #5f2c86;
    border: #5f2c86 solid 2px;
    border-radius: 5px;
    font-size: 1rem;
    color: white;
    text-decoration: none;
    &:hover {
      background: white;
      color: #5f2c86;
    }
    ${(props) =>
      props.primary &&
      css`
        background: #378abd;
        border-color: #378abd;
        &:hover {
          color: #378abd;
        }
      `};
  `,
  UserDetailLink: styled(Link)`
    margin-left: auto;
    padding: 0.5rem 0.75rem;
    background: #5f2c86;
    border: #5f2c86 solid 2px;
    border-radius: 5px;
    font-size: 1rem;
    color: white;
    text-decoration: none;
    align-self: center;
    &:hover {
      background: white;
      color: #5f2c86;
    }
    ${(props) =>
      props.primary &&
      css`
        background: #378abd;
        border-color: #378abd;
        &:hover {
          color: #378abd;
        }
      `};
  `,
  LargeText: styled.p`
    font-size: 1.25rem;
    font-weight: medium;
    margin: 0;
    text-align: left;
    text-decoration: underline;
    margin-bottom: 1rem;
  `,
  SectionWrapper: styled.section`
    display: flex;
  `,

  Back: styled.div`
    font-size: 1.25rem;
    position: absolute;
    left: 20px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #378abd;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  `,
  UserItemWrapper: styled.div`
    flex: 1;
    width: 100%;
    @media (min-width: 450px) {
      flex: 0;
    }
  `,
  InfoHeader: styled.span`
    display: block;
    font-size: 1.4rem;
    color: #5f2c86;
    font-weight: bold;
    margin: 1rem 0;
    & > span {
      color: black;
    }
  `,
  Header3: styled.span`
    font-size: 1.1rem;
    font-weight: bold;
    color: #272e68;
    display: block;
    margin-bottom: 0.75rem;
    white-space: nowrap;
  `,
  ImportantText: styled.span`
    font-size: 1rem;
    font-weight: bold;
    color: black;
    border-bottom: black solid 2px;
  `,
};

export default GenStyles;
