import { useCallback, useState } from 'react';

const useForgotPassword = (username) => {
  const [res, setRes] = useState({ data: null, error: null, isLoading: false });

  const callAPI = useCallback(async () => {
    try {
      setRes((prev) => ({ ...prev, isLoading: true }));
      let data = await fetch(
        process.env.REACT_APP_API_URL + `/api/messaging/sendreset`,
        {
          method: 'POST',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify(username),
        }
      );
      if (data.ok) {
        setRes((prev) => ({ isLoading: false, error: false, data: true }));
      } else {
        throw new Error();
      }
    } catch (err) {
      setRes((prev) => ({ isLoading: false, error: true, data: false }));
    }
  }, [username]);

  return [res, callAPI];
};

export default useForgotPassword;
