import React from 'react';
import DOMPurify from 'dompurify';
const StaticPractice = ({ content }) => {
  return (
    <div
      className='question-wrapper statement'
      dangerouslySetInnerHTML={{
        __html: DOMPurify.sanitize(content),
      }}
    ></div>
  );
};

export default StaticPractice;
