import { useGetData } from '../../../components/core/helperFuncs/handlePracticeGet';
import React from 'react';
import { Context } from '../../../context';
import { FillIn } from './FillIn';

const practiceProps = [
  {
    question: 'Spend at least 15 minutes of child-centered time every day:',
    name: 'practiceRev1-1',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'I was able to spend child-centered time with my child:',
    name: 'practiceRev1-2',
    type: 'dropdown',
    options: ['Everyday', 'Almost every day', 'A few days', 'Not at all'],
  },
  {
    question: 'What were some of the child centered activities that you did?',
    name: 'practiceRev1-3',
    type: 'dropdown',
    options: [
      'Read',
      'Played the child’s choice of games',
      'Danced',
      'Sang',
      'Other',
    ],
  },
  {
    question: 'Use descriptive commenting at least once.',
    name: 'practiceRev1-4',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: (
      <FillIn
        question={'Start a new routine with my child, it was:'}
        dataVarName={'practice1-1'}
      />
    ),
    name: 'practiceRev1-5',
    type: 'radio',
    options: ['YES', 'NO'],
  },
];

export default practiceProps;
