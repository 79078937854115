import { useEffect, useState } from 'react';
export default function useContent(path) {
  let importedPath = import(`../assets/course/PlanIt${path}`);

  const [content, changeContent] = useState(null);

  useEffect(() => {
    importedPath.then((data) => {
      changeContent(data);
    });
  }, []);

  if (!importedPath) {
    return false;
  }
  return [content];
}
