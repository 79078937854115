import React from 'react';

const CompletionQuestionnaire = ({
  confirmAction,
  denyAction,
  modNum,
  completed,
}) => {
  if (completed.loading) {
    return <></>;
  }
  return (
    <>
      <p>
        {completed.res
          ? `✅ You already completed the Module ${modNum} practice checklist! Want to do it again?`
          : `Are you ready to complete the Module ${modNum} practice checklist?`}
      </p>
      <div>
        <button onClick={confirmAction}>YES</button>
        {!completed.res && <button onClick={denyAction}>NO</button>}
      </div>
    </>
  );
};

export default CompletionQuestionnaire;
