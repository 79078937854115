import React, { useState } from 'react';
import styled from 'styled-components';
import GenStyles from '../../../GenStyles';
import { useAdminInfo } from '../../../adminContext';
const S = {
  SectionWrapper: styled.section`
    display: flex;
  `,
  LookUpBox: styled.div`
    margin: auto;
    padding: 1rem;
    border: #378abd solid 2px;
    border-radius: 10px;
    background-color: #8080801a;
    box-shadow: 5px 5px 5px grey;
  `,
  LookUpForm: styled.form`
    width: 250px;
  `,
  LookUpInput: styled.input`
    box-sizing: border-box;
    width: 100%;
    margin-bottom: 1rem;
    font-size: 1rem;
    padding: 0.25rem;
  `,
  SubmitButton: styled.button`
    padding: 0.25rem 0.5rem;
    background: #378abd;
    border: #378abd solid 2px;
    background: white;
    border-radius: 5px;
    font-size: 1rem;
    &:hover {
      background: #378abd;
      color: white;
    }
  `,
  ErrorStatus: styled.span`
    text-align: left;
    color: red;
    margin: 1rem 0;
  `,
  GoodStatus: styled.span`
    text-align: left;
    color: green;
    margin: 1rem 0;
  `,
};

const LookUp = () => {
  const [userID, setUserId] = useState('');
  const [lastName, setLastName] = useState('');
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const { callAPI } = useAdminInfo();
  return (
    <GenStyles.SectionWrapper>
      <S.LookUpBox>
        <GenStyles.LargeText>Parent Lookup</GenStyles.LargeText>
        <S.LookUpForm
          onSubmit={(e) => {
            e.preventDefault();
            callAPI({
              method: 'PUT',
              url: `admin/user?lastname=${lastName}&userid=${userID}`,
            }).then((res) => {
              if (res) {
                setUserId('');
                setLastName('');
                setSuccess(true);
              } else {
                setError(true);
              }
            });
          }}
        >
          <S.LookUpInput
            placeholder='User ID'
            type={'text'}
            required
            value={userID}
            onChange={(e) => {
              setUserId(e.target.value);
              setError(false);
              setSuccess(false);
            }}
          ></S.LookUpInput>
          <S.LookUpInput
            placeholder='Last Name'
            type={'text'}
            required
            value={lastName}
            onChange={(e) => {
              setLastName(e.target.value);
              setError(false);
              setSuccess(false);
            }}
          ></S.LookUpInput>
          <div style={{ display: 'flex' }}>
            <S.SubmitButton type='submit'>Add Parent</S.SubmitButton>
          </div>
          <div style={{ display: 'flex' }}>
            <S.ErrorStatus>{error && 'Not a Valid User'}</S.ErrorStatus>
            <S.GoodStatus>{success && 'User Added'}</S.GoodStatus>
          </div>
        </S.LookUpForm>
      </S.LookUpBox>
    </GenStyles.SectionWrapper>
  );
};

export default LookUp;
