import React, { useEffect, useState } from 'react';
import './nav-buttons.scss';

function NavButtons(props) {
  const [allowContinue, setAllowContinue] = useState(false);

  useEffect(() => {
    let timeout = setTimeout(() => {
      setAllowContinue(true);
    }, Number(process.env.REACT_APP_SECONDS));

    return () => {
      clearTimeout(timeout);
    };
  }, []);
  const handleScroll = () => {
    const bottom =
      Math.ceil(window.innerHeight + window.scrollY) >=
      document.documentElement.scrollHeight - 50;
  };

  React.useEffect(() => {
    window.addEventListener('scroll', handleScroll, {
      passive: true,
    });
    if (
      window.innerHeight + window.scrollY >=
      document.documentElement.scrollHeight - 50
    ) {
    } else {
    }
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [props.size]);

  return (
    <>
      <div className='nav-button-wrap'>
        <div className='button-box'>
          <button onClick={() => props.handleProgress(false)}>Back</button>
          <button
            className={allowContinue ? 'enable' : 'disable'}
            style={{ pointerEvents: !allowContinue ? 'none' : '' }}
            onClick={() => {
              setAllowContinue(false);
              if (allowContinue) {
                props.handleProgress(true);
              }
            }}
            {...(props.submitID && { type: 'submit', form: props.submitID })}
          >
            Continue
          </button>
        </div>
      </div>
    </>
  );
}

export default NavButtons;
