import React, { useEffect } from 'react';
import './video-player.scss';
import Vimeo from '@u-wave/react-vimeo';
import useStore from '../../../customHooks/useStore';
// import ReactPlayer from "react-player"

function VideoPlayer(props) {
  const { language } = useStore((state) => ({
    language: state.language,
  }));

  useEffect(() => {
    if (props.setVideoWatched) {
      props.setVideoWatched(false);
    }
  }, []);

  console.log('props', props.source);

  return (
    <>
      <Vimeo
        video={language === 'es' ? props.source.es : props.source.en}
        controls={true}
        showTitle={true}
        showPortrait={false}
        onEnd={() => {
          if (props.setVideoWatched) {
            props.setVideoWatched(true);
          }
        }}
      />
    </>
  );
}

export default VideoPlayer;
