import { useState, useCallback, useEffect } from 'react';

export const handlePracticeGet = async (identifier) => {
  try {
    let data = await fetch(
      process.env.REACT_APP_API_URL + `/api/practice?name=${identifier}`,
      {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      }
    );
    if (data.ok) {
      let response = await data.json();
      return response;
    } else {
      const errorMessage = await data.text();
      throw new Error(errorMessage);
    }
  } catch (err) {
    console.log(err);
    return null;
  }
};

export const useGetData = (identifier) => {
  const [res, setRes] = useState({ data: '', error: '', isLoading: false });

  const callAPI = useCallback(async () => {
    try {
      setRes((prevRes) => ({ ...prevRes, isLoading: true }));
      let data = await fetch(
        process.env.REACT_APP_API_URL + `/api/practice?name=${identifier}`,
        {
          method: 'GET',
          mode: 'cors',
          headers: {
            'Content-Type': 'application/json',
            Authorization: `Bearer ${localStorage.getItem('token')}`,
          },
        }
      );
      if (data.ok) {
        let { value } = await data.json();
        value = JSON.parse(value);
        setRes((prevRes) => ({ ...prevRes, isLoading: false, data: value }));
      } else {
        const errorMessage = await data.text();
        setRes((prevRes) => ({ ...prevRes, error: errorMessage }));
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  }, [identifier]);

  useEffect(() => {
    callAPI();
  }, []);

  return [res, setRes];
};
