import React, { useEffect, useState, useContext, useRef } from 'react';
import './DevelopmentTip.scss';
import Modal from '../Modal/Modal';
import { ReactComponent as DevTipBlock1 } from '../../../assets/images/blocks1.svg';
import { gsap } from 'gsap';
import { Context } from '../../../context';
export default function DevelopmentTip(props) {
  const context = useContext(Context);
  const [content, setContent] = useState(null);
  const [show, showModal] = useState(false);
  const clicked = useRef(false);
  useEffect(() => {
    setContent({
      __html: context.state.allDevTips[props.mod][props.content - 1].content,
    });
  }, [props.content]);

  useEffect(() => {
    return () => {
      if (
        !clicked.current &&
        !context.state.allDevTips[props.mod][props.content - 1].missed
      ) {
        context.checkDevTips(props.mod, props.content, 'missed');
      }
    };
  }, []);

  const openDevTip = () => {
    // send some logic so that the context knows that this specific devtip was clicked
    showModal(true);
  };
  const animation = () => {
    gsap.to('pulse');
  };
  return (
    <>
      <button
        className='devTip-display'
        onClick={() => {
          openDevTip();
          clicked.current = true;
          context.checkDevTips(
            context.state.curModIndex,
            props.content,
            'clicked'
          );
        }}
      >
        <div className='word-wrapper'>
          <span>Developmental Tip</span>
          {/* <span>Tip</span> */}
        </div>
        <DevTipBlock1 className='pulse' />
      </button>
      <Modal show={show} showModal={showModal}>
        <div className='devtip-wrap'>
          <div className='head'>
            <div className='icon'></div>
          </div>
          <div
            className='devtip-content'
            dangerouslySetInnerHTML={content}
          ></div>
        </div>
      </Modal>
    </>
  );
}
