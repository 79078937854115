import PageContent from './navigation/page-content/page-content';
import { Provider } from './context';
import './App.scss';
import { useEffect } from 'react';
import useFetch from './customHooks/useFetch';
import useStore from './customHooks/useStore';
//initialize app

function App() {
  // this is the top level of the application.

  const { language, setLanguage } = useStore((state) => ({
    language: state.language,
    setLanguage: state.setLanguage,
  }));

  const [res, callGetAPI] = useFetch({
    url: `lang`,
    method: 'GET',
    responseFormat: 'TEXT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  useEffect(() => {
    callGetAPI();
  }, []);

  useEffect(() => {
    if (res.data) {
      setLanguage(res.data);
    } else {
      setLanguage(window.Localize.getLanguage() || 'en');
    }
  }, [res]);

  return (
    <div id='App'>
      <Provider>
        {/* provider will give the app reusable functions and a global state
          - context.js will hold these functions and state variables 
          - pagecontent is going to have the initial routing for the application 
      */}
        <PageContent />
      </Provider>
    </div>
  );
}

export default App;
