import React, { useContext } from 'react';
import styled, { css } from 'styled-components';
import Logo from '../../../assets/images/EZP_logo_8[22].png';
import GenStyles from '../GenStyles';
import logoutFetch from '../../../assets/helpers/logoutFetch';
import { Context } from '../../../context';
const S = {
  UserDetailButton: GenStyles.UserDetailButton,
  Header: styled.header`
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: black solid 1px;
    padding: 1rem;
    gap: 1rem;
    position: relative;
  `,
  LogoWrapper: styled.div`
    background-image: ${Logo};
    height: 50px;

    @media (min-width: 420px) {
      margin: auto;
      position: absolute;
    }
  `,

  AdminName: styled.span`
    font-size: 1.5rem;
  `,
  Image: styled.img`
    height: 100%;
    width: auto;
  `,
  Title: styled.span`
    margin: auto;
  `,
};

const AdminHeader = () => {
  const context = useContext(Context);
  return (
    <S.Header>
      <S.LogoWrapper>
        <S.Image src={Logo} />
      </S.LogoWrapper>
      <S.UserDetailButton
        primary
        onClick={() => {
          logoutFetch();
          context.logout();
        }}
      >
        Log Out
      </S.UserDetailButton>
    </S.Header>
  );
};

export default AdminHeader;
