const hekbadges = [
  {
    badgeName: 'badge11',
    module: 1,
    urlUsed: '#',
    type: 'activity',
  },
  {
    badgeName: 'badge12',
    urlUsed: '#',
    module: 1,
    type: 'activity',
  },
  {
    badgeName: 'badge13',
    urlUsed: '#',
    module: 1,
    type: 'module',
  },
  {
    badgeName: 'badge21',
    urlUsed: '#',
    module: 2,
    type: 'activity',
  },
  {
    badgeName: 'badge22',
    urlUsed: '#',
    module: 2,
    type: 'activity',
  },
  {
    badgeName: 'badge23',
    urlUsed: '#',
    module: 2,
    type: 'module',
  },
  {
    badgeName: 'badge31',
    urlUsed: '#',
    module: 3,
    type: 'activity',
  },
  {
    badgeName: 'badge32',
    urlUsed: '#',
    module: 3,
    type: 'activity',
  },
  {
    badgeName: 'badge33',
    urlUsed: '#',
    module: 3,
    type: 'module',
  },
  {
    badgeName: 'badge41',
    urlUsed: '#',
    module: 4,
    type: 'activity',
  },
  {
    badgeName: 'badge42',
    urlUsed: '#',
    module: 4,
    type: 'activity',
  },
  {
    badgeName: 'badge43',
    urlUsed: '#',
    module: 4,
    type: 'module',
  },
  {
    badgeName: 'badge51',
    urlUsed: '#',
    module: 5,
    type: 'activity',
  },
  {
    badgeName: 'badge52',
    urlUsed: '#',
    module: 5,
    type: 'activity',
  },
  {
    badgeName: 'badge53',
    urlUsed: '#',
    module: 5,
    type: 'module',
  },
  {
    badgeName: 'badge61',
    urlUsed: '#',
    module: 6,
    type: 'activity',
  },
  {
    badgeName: 'badge62',
    urlUsed: '#',
    module: 6,
    type: 'activity',
  },
  {
    badgeName: 'badge63',
    urlUsed: '#',
    module: 6,
    type: 'module',
  },
];

export default hekbadges;
