import React, { useEffect, useState, useContext } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import styled from 'styled-components';
import flow from '../../../../assets/flow.json';
import hekflow from '../../assets/hekflow.json';
import LookUp from './components/LookUp';
import { useAdminInfo } from '../../adminContext';
import { Context } from '../../../../context';
import UserPreview from './components/UserPreview';
const S = {
  //main
  Main: styled.main`
    padding: 1rem;
  `,
  TopWrapper: styled.div`
    position: relative;
    box-sizing: border-box;
  `,
  Back: styled.div`
    font-size: 1.25rem;
    position: absolute;
    left: 20px;
    top: 50%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    color: #378abd;
    font-weight: bold;
    &:hover {
      cursor: pointer;
      text-decoration: underline;
      text-underline-offset: 5px;
    }
  `,

  H2: styled.h2`
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 1rem 0;
  `,
};

const AdminPageHome = () => {
  const filterOptions = {
    newest: 'NEWEST',
    id: 'ID',
    lastName: 'LASTNAME',
  };

  const { res, callAPI } = useAdminInfo();
  const [sortChoice, setSortChoice] = useState(filterOptions.newest);
  const context = useContext(Context);
  useEffect(() => {
    callAPI({ method: 'GET', url: 'Admin/userlist' });
  }, [callAPI]);

  const program = context.state.isAdmin.program;

  const getSortingFunction = (keyName) => {
    return (a, b) =>
      a[keyName]
        .toString()
        .localeCompare(b[keyName].toString(), 'en', { numeric: true });
  };

  const filteredData = (filter) => {
    const dataCopy = [...res.data];
    switch (filter) {
      case filterOptions.newest:
        return dataCopy.reverse();
      case filterOptions.id:
        const sortAlphaNum = getSortingFunction('id');
        return dataCopy.sort(sortAlphaNum);
      case filterOptions.lastName:
        const sortLastName = getSortingFunction('lname');
        return dataCopy.sort(sortLastName);
      default:
        return dataCopy;
    }
  };

  return (
    <>
      <LookUp />
      <section>
        <div style={{ display: 'flex', alignItems: 'center', gap: '20px' }}>
          <S.H2>Parent List</S.H2>
          <select
            value={sortChoice}
            onChange={(e) => setSortChoice(e.target.value)}
          >
            <option value={filterOptions.newest}>Newest</option>
            <option value={filterOptions.id}>User ID</option>
            <option value={filterOptions.lastName}>Last Name</option>
          </select>
        </div>
        {res.data &&
          filteredData(sortChoice).map((item, index) => {
            return program === 'EZP' ? (
              <UserPreview
                key={item.id}
                userID={item.id}
                studyID={item.studyid}
                lastName={item.lname}
                lastLogin={format(parseISO(item.lastlogon), 'LL/dd/y')}
                modNum={item.progress.modindex + 1}
                sectionName={
                  flow[item.progress.modindex + 1].links[
                    JSON.parse(item.progress.linkindex)[item.progress.modindex]
                  ].title
                }
                program={'EZP'}
              />
            ) : (
              <UserPreview
                key={item.id}
                userID={item.id}
                studyID={item.studyid}
                lastName={item.lname}
                lastLogin={format(parseISO(item.lastlogon), 'LL/dd/y')}
                modNum={item.progress.modindex}
                sectionName={
                  item.progress.modindex
                    ? hekflow[item.progress.modindex].links[
                        JSON.parse(item.progress.linkindex)
                      ].title
                    : 'Overview'
                }
                program={'HEK'}
              />
            );
          })}
        {!res.data && !res.isLoading && <div>Error Loading Resources</div>}
      </section>
    </>
  );
};

export default AdminPageHome;
