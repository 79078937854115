import React from 'react';
import LanguageToggle from '../header/LanguageToggle';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';

const LoginHeader = ({ logedInOrSignUp }) => {
  return (
    <div className='sign-in-b-wrapper'>
      <LanguageToggle />
      <div className='right-section'>
        {logedInOrSignUp === 'login' ? (
          <>
            <div>
              <p>Need to Create an Account?</p>
              <p>
                <Link to={'/reset-password'}>
                  I forgot my Username or Password
                </Link>
              </p>
            </div>
            <Link className='sign-up' to='/sign-up'>
              Create new account
            </Link>
          </>
        ) : (
          <>
            <div>
              <p>Already have an account?</p>
              <p>
                <Link to={'/reset-password'}>I forgot my Password</Link>
              </p>
            </div>
            <Link className='sign-up' to='/login'>
              Sign In
            </Link>
          </>
        )}
      </div>
    </div>
  );
};

export default LoginHeader;
