import React, { Component } from 'react';
import LoginService from './utils/services/login';
import Flow from './assets/flow.json';
import AssetService from './utils/asset-load';
import AllBagOfTricksJson from './assets/course/BagOfTricks/bag-of-tricks.json';
import allTips from './assets/course/devTips/allTips';
import 'react-toastify/dist/ReactToastify.css';
import postDevTipsClicked from './assets/helpers/postDevTipsClicked';
export const Context = React.createContext();
export class Provider extends Component {
  state = {
    isSidebarOpen: false,
    notifications: [],
    curModIndex: '',
    lastVisitedModIndex: 0,
    linkIndexes: [],
    curModLink: '',
    disabledArray: [],
    completedArray: [],
    completedBadges: [],
    loading: true,
    selectedIgnoreOptionsContext: [],
    bagOfTricks: [],
    missedDevTips: [],
    allDevTips: localStorage.getItem('tips')
      ? JSON.parse(localStorage.getItem('tips'))
      : allTips,
    isAuth: { auth: false, loading: true },
    allBagOfTricks: [],
    linkArray: [],
    progress: {},
    maxLengthLinkArray: [],
    furthestMod: 0,
    curSlide: 0,
    isAdmin: { isAdmin: false, program: null },
  };

  setFurthestMod = (arr) => {
    return arr.indexOf(0) === -1 ? arr.length : arr.indexOf(0);
  };

  changeProgressState = (module, progress) => {};

  progressInit = (firstLogin = false) => {
    if (localStorage.progress) {
      const progress = JSON.parse(localStorage.progress);
      this.progress = {
        modindex: progress['modindex'],
        linkindex: JSON.parse(progress['linkindex']),
      };
      const bagOfTricks = [];
      if (localStorage.bagOfTricks) {
        const parsedTricks = JSON.parse(localStorage.bagOfTricks);
        Object.keys(AllBagOfTricksJson).map((keyName) => {
          AllBagOfTricksJson[keyName].map((trick, index) => {
            parsedTricks.map((existedTricks) => {
              if (existedTricks === trick.title) {
                trick['active'] = true;
                bagOfTricks.push(trick);
              }
            });
          });
        });
      }
      // Create an array that contains the possible max number of lessons for each module
      let maxLengthLinkArray = [];
      let obj = {};
      Flow.map((item, i) => {
        let split = item.url.split('/');
        obj[split[2]] = { length: item.links.length };
        if (i !== 0) {
          maxLengthLinkArray.push(obj[split[2]].length - 1);
        }
      });

      // I need an unlocked link data point
      this.setModProgressNumber();

      let forwardProgress;
      let disabledArray = [];
      // checks to see if the top level progress has been reached in the Progress object.
      this.progress.linkindex.map((num, i) => {
        if (localStorage.initialVideo === 'false') {
          disabledArray.push(Flow[i + 1].url);
        } else if (i >= this.progress.modindex) {
          if (Flow[i + 2]) {
            disabledArray.push(Flow[i + 2].url);
          }
        }

        if (Flow[i + 1].links[num] === undefined) {
          const length = Flow[i + 1].links.length;
          this.state.linkArray.push(Flow[i + 1].links[length - 1]);
        } else {
          this.state.linkArray.push(Flow[i + 1].links[num]);
        }
      });

      this.setState({
        // forward submodule progress in each module
        lastVisitedModIndex: this.progress.modindex,
        linkIndexes: this.progress.linkindex,
        // object containing the forward link information
        linkArray: this.state.linkArray,
        // how the sidebar knows which links to grey out.
        furthestMod: this.setFurthestMod(this.progress.linkindex),
        disabledArray: disabledArray,
        curModLink: this.state.linkArray[this.progress.modindex].url,
        loading: false,
        allBagOfTricks: AllBagOfTricksJson,
        bagOfTricks: bagOfTricks,
      });
    }
  };

  getProgressData = async (updateProg = true) => {
    const url = process.env.REACT_APP_API_URL + '/api/progress';
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          this.logout();
        }
      } else {
        const json = await response.json();
        const stringResult = JSON.stringify(json);
        if (json['linkindex']) {
          json['linkindex'] = JSON.parse(json['linkindex']);
        }
        localStorage.setItem('progress', stringResult);

        if (localStorage.getItem('coach') !== 'C' && updateProg) {
          this.progressInit();
        }

        return stringResult || true;
      }
    } catch (error) {
      return false;
    }
  };

  postProgressData = async (curModIndex) => {
    const url = process.env.REACT_APP_API_URL + '/api/progress';
    let payload = {
      modindex: curModIndex,
      linkindex: JSON.stringify(this.state.linkIndexes),
    };
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });
      if (!response.ok) {
        if (response.status === 401) {
          this.logout();
        }
      } else {
        const stringResult = JSON.stringify(payload);
        localStorage.setItem('progress', stringResult);
        this.progressInit();
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  getTricksData = async (firstLogin) => {
    const url = process.env.REACT_APP_API_URL + '/api/bagoftricks';
    try {
      const response = await fetch(url, {
        method: 'GET',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (!response.ok) {
        if (response.status === 401) {
          this.logout();
        }
        throw new Error('bad request');
      } else {
        const json = await response.json();
        const stringResult = JSON.stringify(json);
        localStorage.setItem('bagOfTricks', stringResult);
        if (json) {
          const bagOfTricks = [];
          Object.keys(AllBagOfTricksJson).map((keyName) => {
            AllBagOfTricksJson[keyName].map((trick, index) => {
              json.map((existedTricks) => {
                if (existedTricks === trick.title) {
                  trick['active'] = true;
                  bagOfTricks.push(trick);
                }
              });
            });
          });
          this.setState({
            bagOfTricks: bagOfTricks,
          });
        }
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  postTrickData = async (trick) => {
    const url = process.env.REACT_APP_API_URL + '/api/bagoftricks';
    let payload = trick;
    try {
      const response = await fetch(url, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        let bagOfTricks = localStorage.getItem('bagOfTricks');
        if (bagOfTricks) {
          bagOfTricks = JSON.parse(bagOfTricks);
          bagOfTricks.push(trick);
          bagOfTricks = JSON.stringify(bagOfTricks);
          localStorage.setItem('bagOfTricks', bagOfTricks);
        }
        return true;
      } else {
        if (response.status === 401) {
          this.logout();
        }
        return false;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  deleteTrickData = async (trick) => {
    const url = process.env.REACT_APP_API_URL + '/api/bagoftricks';
    let payload = trick;
    try {
      const response = await fetch(url, {
        method: 'DELETE',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify(payload),
      });
      if (response.ok) {
        let bagOfTricks = localStorage.getItem('bagOfTricks');
        if (bagOfTricks) {
          bagOfTricks = JSON.parse(bagOfTricks);
          bagOfTricks = bagOfTricks.filter((item) => item !== trick);
          bagOfTricks = JSON.stringify(bagOfTricks);
          localStorage.setItem('bagOfTricks', bagOfTricks);
        }
        return true;
      } else {
        if (response.status === 401) {
          this.logout();
        }
        return false;
      }
    } catch (error) {
      console.log('error', error);
    }
  };

  setModProgressNumber = () => {
    let hash = {};
    let allLinks = [];
    let maxLengthLinkArray = [];
    Flow.map((item, i) => {
      let split = item.url.split('/');
      hash[split[2]] = { length: item.links.length };
      if (i !== 0) {
        maxLengthLinkArray.push(hash[split[2]].length);
      }
      let array = [];
      item.links.map((linkObject) => {
        let urlArray = linkObject.url.split('/');
        let timeline = AssetService.getTimeline(urlArray[4], urlArray[2]);
        array.push(timeline.length);
        timeline.map((data) => {
          allLinks.push(data.url);
        });
      });
      hash[split[2]].subLengths = array;
    });

    // let forwardProgress = allLinks[Progress.progress.forwardProgress];
    // let disabled = allLinks.slice(Progress.progress.forwardProgress);
    // this.setState(
    //   {
    //     forwardProgress: forwardProgress,
    //     allLinks: allLinks,
    //     modSubLengths: hash,
    //     subLinksDisabledArray: disabled,
    //     loading: false,
    //   },
    //   () => {}
    // );
    this.setState({
      allLinks: allLinks,
      modSubLengths: hash,
      maxLengthLinkArray: maxLengthLinkArray,
    });
  };

  setTimelineProgress = (timelineNumber, location) => {
    let completedArray = [...this.state.completedArray, location];
    this.setState({
      completedArray: completedArray,
    });
  };

  setIgnoreOptionsContext = (selectedIgnoreOptionsContext) => {
    this.setState({
      selectedIgnoreOptionsContext: selectedIgnoreOptionsContext,
    });
  };

  setBagOfTricksContext = async (bagOfTricks, trick, method) => {
    let check;
    if (method === 'POST') {
      check = await this.postTrickData(trick);
    } else if (method === 'DELETE') {
      check = await this.deleteTrickData(trick);
    }
    if (check) {
      this.setState({
        bagOfTricks: bagOfTricks,
      });
      return true;
    } else {
      return false;
    }
  };
  setActiveForAllBagOfTricksContext = (allBagOfTricks) => {
    this.setState({
      allBagOfTricks: allBagOfTricks,
    });
  };

  setCurModLink = async (curModLink) => {
    this.setState({
      curModLink: curModLink,
    });
  };

  setCurModIndex = async (curModIndex) => {
    this.setState({
      curModIndex: curModIndex,
    });
    const prevModIndex = JSON.parse(localStorage.progress).modindex;
    if (curModIndex > prevModIndex) {
      try {
        const response = await fetch(
          process.env.REACT_APP_API_URL + '/api/progress',
          {
            method: 'POST',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${localStorage.getItem('token')}`,
            },
            body: JSON.stringify({
              modindex: curModIndex,
              linkindex: JSON.stringify(this.state.linkIndexes),
            }),
          }
        );
        if (response.ok) {
        } else {
          if (response.status === 401) {
            this.logout();
          }
        }
      } catch (error) {
        console.log('error', error);
      }
    }
  };

  setLastVisitedModIndex = (lastVisitedModIndex) => {
    this.setState({
      lastVisitedModIndex: lastVisitedModIndex,
    });
  };

  setCurSectionIndex = (curSectionIndex) => {
    this.setState({
      curSectionIndex: curSectionIndex,
    });
  };

  updateDisabledArray = (removedItem) => {
    this.setState({
      disabledArray: this.state.disabledArray.filter(
        (arrayUrl) => arrayUrl !== removedItem
      ),
      curModIndex: this.state.curModIndex + 1,
    });
  };

  setDisabledArray = () => {
    this.setState({
      disabledArray: this.state.disabledArray.filter(
        (arrayUrl) => arrayUrl !== '/course/module1'
      ),
    });
  };

  setSidebarModuleOpen = (curModIndex) => {
    this.setState({
      curModIndex: curModIndex,
    });
  };

  setUpdateLinkIndexes = (curModIndex) => {
    let newlinkIndexes = this.state.linkIndexes;
    newlinkIndexes[curModIndex] = newlinkIndexes[curModIndex] + 1;
    this.setState(
      {
        linkIndexes: newlinkIndexes,
        furthestMod: this.setFurthestMod(newlinkIndexes),
      },
      () => {
        this.postProgressData(curModIndex);
      }
    );
  };

  login = (token, firstLogin = false) => {
    if (token) {
      this.setState({
        isSidebarOpen: false,
        notifications: [],
        curModIndex: '',
        lastVisitedModIndex: 0,
        linkIndexes: [],
        curModLink: '',
        disabledArray: [],
        completedArray: [],
        completedBadges: [],
        loading: true,
        selectedIgnoreOptionsContext: [],
        bagOfTricks: [],
        missedDevTips: [],
        allDevTips: localStorage.getItem('tips')
          ? JSON.parse(localStorage.getItem('tips'))
          : allTips,
        isAuth: { auth: true, loading: false },
        allBagOfTricks: [],
        linkArray: [],
        progress: {},
        maxLengthLinkArray: [],
        furthestMod: 0,
        curSlide: 0,
      });
      localStorage.setItem('token', token);
      this.getProgressData();
      this.getTricksData(firstLogin);
    }
    this.setState({
      isAuth: { auth: true, loading: false },
    });
  };

  logout = () => {
    localStorage.clear();
    this.setState({
      isAuth: { auth: false, loading: false },
      isAdmin: { isAdmin: false, program: null },
      isSidebarOpen: false,
      notifications: [],
      curModIndex: '',
      lastVisitedModIndex: 0,
      linkIndexes: [],
      curModLink: '',
      disabledArray: [],
      completedArray: [],
      completedBadges: [],
      loading: false,
      selectedIgnoreOptionsContext: [],
      bagOfTricks: [],
      missedDevTips: [],
      allDevTips: [],
      allBagOfTricks: [],
      linkArray: [],
      progress: {},
      maxLengthLinkArray: [],
      furthestMod: 0,
      curSlide: 0,
    });
  };

  toggleMenu = () => {
    this.setState({
      isSidebarOpen: !this.state.isSidebarOpen,
    });
  };
  closeMenu = () => {
    this.setState({
      isSidebarOpen: false,
    });
  };

  loginFetch = (userId) => {
    LoginService.login(userId);
    this.setState({
      userId: userId,
    });
  };
  setModalContent = (content) => {
    this.setState({
      modalContent: content,
    });
  };

  addCompletedBadge = (badge) => {
    this.setState({
      completedBadges: [...this.state.completedBadges, badge],
    });
  };

  unlockModule = (link) => {};

  setMissedDevTips = (tip) => {
    this.setState({
      missedDevTips: [...this.state.missedDevTips, tip],
    });
  };

  checkDevTips = (moduleNum, tipNum, action) => {
    const newTips = [...this.state.allDevTips];
    if (action === 'clicked') {
      postDevTipsClicked({
        module: moduleNum,
        tip: newTips[moduleNum][tipNum - 1].id - 1,
      });
      newTips[moduleNum][tipNum - 1].clicked =
        !newTips[moduleNum][tipNum - 1].clicked;
    } else if (action === 'both') {
      newTips[moduleNum][tipNum - 1].clicked = true;
      newTips[moduleNum][tipNum - 1].missed = false;
    } else {
      if (!newTips[moduleNum][tipNum - 1].clicked) {
        newTips[moduleNum][tipNum - 1].missed = true;
      }
    }

    localStorage.setItem('tips', JSON.stringify(newTips));
    this.setState({
      allDevTips: newTips,
    });
  };

  submitForm = async (idName, payload) => {};

  setCurSlide = (num) => {
    this.setState({
      curSlide: num,
    });
  };

  setIsAdmin = (admin, program) => {
    this.setState({
      isAdmin: {
        isAdmin: admin,
        program: program,
      },
    });
  };

  render() {
    return (
      <Context.Provider
        value={{
          state: this.state,
          closeMenu: this.closeMenu,
          toggleMenu: this.toggleMenu,
          loginFetch: this.loginFetch,
          setTimelineProgress: this.setTimelineProgress,
          unlockModule: this.unlockModule,
          setCurrentModule: this.setCurrentModule,
          setIgnoreOptionsContext: this.setIgnoreOptionsContext,
          setBagOfTricksContext: this.setBagOfTricksContext,
          setActiveForAllBagOfTricksContext:
            this.setActiveForAllBagOfTricksContext,
          login: this.login,
          setCurModLink: this.setCurModLink,
          setLastVisitedModIndex: this.setLastVisitedModIndex,
          setCurModIndex: this.setCurModIndex,
          setCurSectionIndex: this.setCurSectionIndex,
          updateDisabledArray: this.updateDisabledArray,
          setSidebarModuleOpen: this.setSidebarModuleOpen,
          setUpdateLinkIndexes: this.setUpdateLinkIndexes,
          addCompletedBadge: this.addCompletedBadge,
          setMissedDevTips: this.setMissedDevTips,
          checkDevTips: this.checkDevTips,
          logout: this.logout,
          submitForm: this.submitForm,
          getProgressData: this.getProgressData,
          setCurSlide: this.setCurSlide,
          setIsAdmin: this.setIsAdmin,
          setDisabledArray: this.setDisabledArray,
        }}
      >
        {this.props.children}
      </Context.Provider>
    );
  }
}

export const Consumer = Context.Consumer;
