export const getGrammar = (index, arrayLength) => {
  const indexedLength = arrayLength - 1;
  if (index < indexedLength - 1) {
    return ', ';
  } else if (index === indexedLength - 1) {
    return ', and ';
  } else {
    return '';
  }
};
