import handout11eng from '../../../assets/handouts/ho11.pdf';
import handout11es from '../../../assets/handouts/espanol/ho11_espanol.pdf';

import handout21eng from '../../../assets/handouts/ho21.pdf';
import handout21es from '../../../assets/handouts/espanol/ho21_espanol.pdf';

import handout22eng from '../../../assets/handouts/ho22.pdf';
import handout22es from '../../../assets/handouts/espanol/ho22_espanol.pdf';

import handout23eng from '../../../assets/handouts/ho23.pdf';
import handout23es from '../../../assets/handouts/espanol/ho23_espanol.pdf';

import handout24eng from '../../../assets/handouts/ho24.pdf';
import handout24es from '../../../assets/handouts/espanol/ho24_espanol.pdf';

import handout31eng from '../../../assets/handouts/ho31.pdf';
import handout31es from '../../../assets/handouts/espanol/ho31_espanol.pdf';

import handout32eng from '../../../assets/handouts/ho32.pdf';
import handout32es from '../../../assets/handouts/espanol/ho32_espanol.pdf';

import handout41eng from '../../../assets/handouts/ho41.pdf';
import handout41es from '../../../assets/handouts/espanol/ho41_espanol.pdf';

import handout42eng from '../../../assets/handouts/ho42.pdf';
import handout42es from '../../../assets/handouts/espanol/ho42_espanol.pdf';

import handout51eng from '../../../assets/handouts/ho51.pdf';
import handout51es from '../../../assets/handouts/espanol/ho51_espanol.pdf';

import handout52eng from '../../../assets/handouts/ho52.pdf';
import handout52es from '../../../assets/handouts/espanol/ho52_espanol.pdf';

import handout53eng from '../../../assets/handouts/ho53.pdf';
import handout53es from '../../../assets/handouts/espanol/ho53_espanol.pdf';

import handout61eng from '../../../assets/handouts/ho61.pdf';
import handout61es from '../../../assets/handouts/espanol/ho61_espanol.pdf';

import handout62eng from '../../../assets/handouts/ho62.pdf';
import handout62es from '../../../assets/handouts/espanol/ho62_espanol.pdf';

import handout63eng from '../../../assets/handouts/ho63.pdf';
import handout63es from '../../../assets/handouts/espanol/ho63_espanol.pdf';

let allHandouts = {
  handout11: {
    en: handout11eng,
    es: handout11es,
  },
  handout21: {
    en: handout21eng,
    es: handout21es,
  },
  handout22: {
    en: handout22eng,
    es: handout22es,
  },
  handout23: {
    en: handout23eng,
    es: handout23es,
  },
  handout24: {
    en: handout24eng,
    es: handout24es,
  },
  handout31: {
    en: handout31eng,
    es: handout31es,
  },
  handout32: {
    en: handout32eng,
    es: handout32es,
  },
  handout41: {
    en: handout41eng,
    es: handout41es,
  },
  handout42: {
    en: handout42eng,
    es: handout42es,
  },
  handout51: {
    en: handout51eng,
    es: handout51es,
  },
  handout52: {
    en: handout52eng,
    es: handout52es,
  },
  handout53: {
    en: handout53eng,
    es: handout53es,
  },
  handout61: {
    en: handout61eng,
    es: handout61es,
  },
  handout62: {
    en: handout62eng,
    es: handout62es,
  },
  handout63: {
    en: handout63eng,
    es: handout63es,
  },
};

export default allHandouts;
