import { useState, useEffect } from 'react';

export const useFetch = () => {
  const [data, setData] = useState('');
  const [error, setError] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const data = await Promise.resolve('Example Example Example');

        setData(data);
      } catch (error) {
        console.log(error);
        setError(true);
      }
    };
    fetchData();
  }, []);
  return [data, error];
};
