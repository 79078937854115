import React, { useState } from 'react';
import RadioInputWrapper from './form-wrappers/RadioInputWrapper';
import DropDownWrapper from './form-wrappers/DropDownWrapper';
import TextWrapper from './form-wrappers/TextWrapper';
import usePractiveReview from '../../assets/helpers/usePractiveReview';

import postPractiveReview from '../../assets/helpers/postPractiveReview';
import { CheckList } from '../Form/CheckList';
//onSubmit needs to be made when we know where to send data
const PracticeChecklist = ({
  formArr,
  submitButton,
  setSubmitted,
  admin,
  module,
}) => {
  const [state, setState] = useState({});
  const dataState = usePractiveReview(module);

  const handleChange = (e) => {
    setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  };

  const handleCheckChange = (e) => {
    const checkOrUncheck = (checkedArray) => {
      if (e.target.checked) {
        return [...checkedArray, e.target.value];
      } else {
        return checkedArray.filter((item) => item !== e.target.value);
      }
    };

    setState((prevState) => ({
      ...prevState,
      checkGroup: checkOrUncheck(
        prevState.checkGroup ? prevState.checkGroup : []
      ),
    }));
  };

  // const handleCheckboxChange = (e) =>
  //   setState((prevState) => ({
  //     ...prevState,
  //     [e.target.name]:
  //   }));

  const pickComponent = (item, state, handleChange) => {
    switch (item.type) {
      case 'radio':
        return (
          <RadioInputWrapper
            item={item}
            state={state}
            handleChange={handleChange}
          />
        );
      case 'dropdown':
        return (
          <DropDownWrapper
            item={item}
            state={state}
            handleChange={handleChange}
          />
        );
      case 'checkbox':
        return (
          <div>
            <p>{item.question}</p>
            <CheckListItems
              options={item.options}
              onChange={handleCheckChange}
              checkedItems={state.checkGroup ? state.checkGroup : []}
            />
          </div>
        );
      default:
        return (
          <TextWrapper item={item} state={state} handleChange={handleChange} />
        );
    }
  };

  return (
    <section className='checklist-wrapper'>
      <form
        onSubmit={async (e) => {
          e.preventDefault();
          if (Object.keys(state).length) {
            postPractiveReview({
              module: Number(module),
              name: module,
              value: JSON.stringify(state),
            });
            setSubmitted();
          }
        }}
      >
        <h2>{admin ? `Module ${module}` : 'Practice Review'}</h2>
        <div className='checklist-section question border-bottom'>
          <p className='able-to description'>I was able to:</p>
          <span>YES</span> <span>NO</span>
        </div>
        {formArr.map((item, index) => (
          <section className={`question question-${item.type}`} key={index}>
            {pickComponent(item, state, handleChange)}
          </section>
        ))}
        {!admin && (
          <button
            type='submit'
            className={`submit-button ${
              Object.keys(state).length ? '' : 'disable'
            }`}
          >
            {submitButton}
          </button>
        )}
      </form>
    </section>
  );
};

const CheckListItems = ({ options, onChange, checkedItems }) => {
  return (
    <>
      {options.map((item, index) => {
        return (
          <div className='select-item-wrap'>
            <input
              value={item}
              type='checkbox'
              id={`checkbox-${index}`}
              checked={checkedItems.includes(item)}
              onChange={(e) => onChange(e)}
            />
            <label htmlFor={`checkbox-${index}`}>{item}</label>
          </div>
        );
      })}
    </>
  );
};

export default PracticeChecklist;
