import React from 'react';
import styled from 'styled-components';
import AdminHeader from '../components/AdminHeader';
import UserDetailPage from './user-detail/UserDetailPage';
const S = {
  Main: styled.main`
    padding: 1rem;
  `,
};

const DetailPage = () => {
  return (
    <>
      <AdminHeader />
      <S.Main>
        <UserDetailPage />
      </S.Main>
    </>
  );
};

export default DetailPage;
