export const findPracticeAnswer = (practiceItems, keyName) => {
  let item = practiceItems.find((item) => item.name === keyName);
  if (item) {
    item = JSON.parse(item.value);
    if (Array.isArray(item)) {
      return item.join(', ');
    }
    return item;
  } else {
    return 'Not Answered';
  }
};
