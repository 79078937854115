let miss = [{ module: 1, key: 4 }];

const allTips = [
  [
    {
      content:
        "<h3>Developmental Tip: Child-Centered Time - 1</h3>Child-centered time works great even if your child is not using words. Choose 3-4 activities or toys and show them to your child. Your child may choose their activity by pointing, looking, or simply starting to play. As your child plays, follow their lead and join in the play.",
      id: 1,
      clicked: false,
      title: "Child-Centered Time - 1",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Descriptive Commenting</h3><p> Using descriptive commenting to describe aloud what your child is doing builds language skills and helps keep them engaged. </p>",
      id: 2,
      clicked: false,
      title: "Descriptive Commenting",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Child-Centered Time - 2</h3><h4>Here are some ideas of child-centered activities for younger children: </h4><ul> <li>Physical games like hide-and-seek and peek-a-boo</li> <li>Bath time with toys and cups </li> <li>Playing with blocks (stacking, sorting, building), shape-sorters and puzzles </li> <li>Playing with bubbles or chalk </li> <li>Coloring with large crayons and markers that are easy to grip </li> <li>Playing pretend play with dolls or stuffed animals (e.g.., feeding, bathing, putting them to sleep, throwing them a party) </li></ul><p>For children who are not using words, pay extra attention to how you join in the play activity and give your child your full attention. Your eye contact and smiles are important ways to tell your child that you are paying attention to them. Do your best to keep the phones and tablets away during this time.</p>",
      id: 3,
      clicked: false,
      title: "Child-Centered Time - 2",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Routines - 1</h3>If your child receives early intervention therapies like physical (PT), occupational (OT) or speech (ST) therapy, combining therapy exercises with routines like songs or games can help your child feel more comfortable and know what to expect during these activities.",
      id: 4,
      clicked: false,
      title: "Developmental Tip: Routines - 1",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Routines - 2</h3>It’s never too early or too late to start a family routine like regular family mealtimes. Even if you are not all eating the same foods, sitting together, putting phones and tablets away, and focusing on one another is a great way to help children feel safe, loved, and valued as a member of the family.",
      id: 5,
      clicked: false,
      title: "Routines - 2",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Reading with Young Children</h3><p>It’s never too early to start reading to your child! Even if your child is not using words, reading is an important routine to build your child’s brain. Here are some ideas to make reading enjoyable:</p>  <ul><li>Sing the story to your child. Young children love to hear their parents sing</li><li>Read books with flaps, mirrors, fabric pages or pages that crinkle. Children  enjoy the sensations that come along with these types of books</li><li>Read before nap or bedtime when your child might have an easier time sitting still and cuddling </li> <li>Get cozy! Read in a comfortable spot in the house with blankets and pillows</li><li>Look at pictures and describe them! Young children (under the age of 2) are more interested in photographs than cartoons. You can name or describe the things or actions in the photographs or make up a story about the picture.</li></ul>",
      id: 6,
      clicked: false,
      title: "Reading with Young Children",
      missed: false,
    },
  ],
  [
    {
      content:
        "<h3>Developmental Tip: Labeled Praise - 1</h3>If your child is not yet speaking or speaking in sentences less than 3 words, keep it simple! Use short sentences, “Great job sharing” or “I like when you help me clean up!” Combine the praise with a hug, big smile, or high-five to reinforce the praise!",
      id: 1,
      clicked: false,
      title: "Labeled Praise - 1",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Labeled Praise - 2</h3>Use labeled praise to encourage new developmental skills. When you see your child trying something that you know is developmentally new or difficult, pay attention to it by using labeled praise! Here is an example: for a child learning how to go up and down the stairs by themselves, you can say, “good job holding onto the railing!”",
      id: 2,
      clicked: false,
      title: "Labeled Praise - 2",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Reward Programs</h3><p>Make sure your child is able to understand your reward program! Make it simple and achievable. Also, make sure they are developmentally ready to use rewards, these include milestones like:</p><ul><li>Your child speaks in 2-3 word sentences</li><li>Your child can follow a 2-step instruction, for example, “Get your socks and come to the kitchen.”</li></ul><p>If your child is not ready for rewards, keep using labeled praise and your time and attention to see more of the behaviors that you want to see!</p>",
      id: 3,
      title: "Reward Programs",
      missed: false,
    },
  ],
  [
    {
      content:
        "<h3>Developmental Tip: <i>DO</i> Statements</h3>Depending on your child’s developmental stage, they may need help with complying with your <i>DO</i> statement and that’s ok! Helping them be successful with your <i>DO</i> statement will give them a sense of accomplishment and teach them how and what to do.",
      id: 1,
      clicked: false,
      title: "DO Statements",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Question Commands</h3>When children are developing their language, question commands can be extra confusing! They are not likely to understand that your questions are sometimes meant as directions to be followed.",
      id: 2,
      clicked: false,
      title: "Question Commands",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Warning Statements</h3>Many young children don’t yet understand the concept of time. Warning statements that involve counting or numbers of activities can help many young children know what to expect next. For example, “I’m going to count to 10 and then we are going to start cleaning up the toys” OR “You can go down the slide one more time and then we are leaving the park.”",
      id: 3,
      clicked: false,
      title: "Warning Statements",
      missed: false,
    },
  ],
  [
    {
      content:
        "<h3>Developmental Tip: Natural <i>If/Then</i> Statements</h3>To help children understand natural <i>if/then</i> statements, it is helpful to say the <i>if/then</i> but also help the child make the desired choice. For example, in the video, the mother tells her daughter that if she squeezes too hard, she will spill the juice, but then helps her hold the juice box. If a child does not understand concepts like sizes (<i>big versus little</i>), colors, location (<i>on top, underneath, next to, or behind</i>) they will need help with making good choices.  Natural <i>if/thens</i> are great ways to help teach children about how their behavior can lead to something happening the child might not like.",
      id: 1,
      clicked: false,
      title: "Natural If/Then Statements",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Logical If/Then</i> Statements</h3>Logical <i>if/then</i> statements should <u>NOT</u> be used with children less than 2 years old because children that young are not old enough to understand how logical <i>if/thens</i> work and are still learning how their behavior can cause other things to happen.",
      id: 2,
      clicked: false,
      title: "Logical If/Then Statements",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Distraction</h3>Distraction is a good strategy for children across many different developmental stages. Distraction works just as well for children who are not using words as it does for children who use sentences and are growing more independent. In fact, distractions work particularly well with young children with short attention spans because they can be easily distracted to ward off a tantrum, whining, or destructive behavior.",
      id: 3,
      clicked: false,
      title: "Distraction",
      missed: false,
    },
  ],
  [
    {
      content:
        "<h3>Developmental Tip: Is Your Child Ready For Time-Outs?</h3>Generally, time-outs should not be used for children younger than 2 years old. Time-outs can be used when your child is able to follow <i>do</i> statements like “pick up the toy,” or “hold my hand” almost every time. If your child is not ready for time-outs, it is still helpful to learn about them so that you know this strategy later when your child is ready. You also might learn some things that you can use with your other children or share with friends or family. If your child is not ready for time-outs keep using other strategies you’ve learned to manage misbehaviors",
      id: 1,
      clicked: false,
      title: "Is Your Child Ready For Time-Outs",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Time-Out - 1</h3>Keep it simple and be clear and calm! For example, if your child is teasing, tell them, “If you continue to tease your brother, then you will need take a time-out.” ",
      id: 2,
      clicked: false,
      title: "Time-Out - 1",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Time-Out - 2</h3>The point of time-out is to stop misbehavior immediately. If your child has a short attention span, you can use shorter periods of time. You can also keep track of the time with a kitchen timer or alarm on your phone. Let your child know you are starting the time. It is sometimes helpful if they can see the timer too! ",
      id: 3,
      clicked: false,
      title: "Time-Out - 2",
      missed: false,
    },
  ],
  [
    {
      content:
        "<h3>About Developmental Tips for Module 6</h3>Tips will focus on specific stressors and concerns that parents may experience related to having a child who was born prematurely (a “preemie”). Sometimes, memories of the NICU or worry about a child’s health, development, or behavior cause parents to act extra careful with their children or make it hard to set limits or treat them the same as a child who wasn’t born early. Also, sometimes additional stress can make it hard for parents to feel connected to their child and other family members. Tips in this module are developed to help you with the unique stressors experienced as a parent of a child born prematurely.<p>As you learn different stress reduction strategies in this module, think about how you can apply them to deal with the things that make you feel stressed.</p>",
      id: 1,
      clicked: false,
      title: "Tips for Module 6",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Take 5 Breathing Exercise</h3><p>Sometimes, therapy sessions or doctor’s appointments for children born prematurely can be stressful! The next time you or your child are feeling stressed during a session or appointment, try the Take 5 breathing exercise together!</p> See the handout below to review the Take 5 Breathing Exercise</p>",
      id: 2,
      clicked: false,
      title: "Take 5 Breathing Exercise",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Time-Out for Parents</h3>Parenting a child that was born prematurely can sometimes be an emotional rollercoaster. Some children born prematurely might have difficulty controlling their behaviors and emotions and look to you for clues about how to behave and to emotionally calm themselves. If you are feeling particularly stressed or overwhelmed with the demands of your child, take some time for yourself, a parent time-out. Sometimes it only needs to be a short time like a quick trip to another room and sometimes a bit longer like a trip to the grocery store by yourself after finding another adult to watch your child. The more that you are in control of your behaviors and emotions, the more you can help your child.",
      id: 3,
      clicked: false,
      title: "Time-Out for Parents",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Reframing</h3> <p>Sometimes parents think that certain behaviors occur because their child was born prematurely—this can make your child’s behavior feel even more stressful! Reframed thoughts and using CPP strategies can be very helpful. Here are two examples for reframing thoughts about behavior related to prematurity:</p><ul><li>Stressful Child Behavior: Child crying and whining during early intervention (EI) therapy exercises</li><li>Upsetting thought: “They went through so much in the NICU, I can’t make them go through this too”</li><li>Common Parent Response: Stopping the exercise, stopping EI, or not practicing</li><li>Reframed Thought: This is hard for them to do but it’s important. I will help my child learn that they can do hard things and be OK.</li><li>New Parent Response: Routines and Distraction: Use favorite songs to comfort and soothe</li></ul>",
      id: 4,
      clicked: false,
      title: "Reframing",
      missed: false,
    },
    {
      content:
        "<h3>Developmental Tip: Affirmations</h3>Parenting a child born prematurely can be especially stressful if they are having developmental or behavioral challenges. Here are some affirmations that might be useful: <ul><li>No matter how scary, I have supported my child through all of their medical treatments </li><li>I love my child, no matter what challenges they may face</li><li>Even though they may take a little longer, my child is achieving milestones.</li><li>I am strong and so is my child; I see my child’s strength</li><li>I am someone who will rise to do whatever is needed to help my child</li><li>I am the expert on my child, everything they have been through, and what they need</li></ul>",
      id: 5,
      clicked: false,
      title: "Affirmations",
      missed: false,
    },
  ],
];

export default allTips;
