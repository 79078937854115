export const handlePracticeSubmit = async (idName, payload) => {
  if (payload?.length === 0) {
    payload = false;
  }
  if (payload) {
    payload = JSON.stringify(payload);
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + `/api/practice`, {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
        body: JSON.stringify({ name: idName, value: payload }),
      });
      if (data.ok) {
      } else {
        const errorMessage = await data.text();
        throw new Error(errorMessage);
      }
    } catch (err) {
      console.log(err);
    }
  }
};
