import { useCallback, useState } from 'react';

const useFetch = ({ url, method, payload, responseFormat, headers = {} }) => {
  const [res, setRes] = useState({ data: null, error: null, isLoading: false });

  const callAPI = useCallback(
    async (overrideUrl) => {
      try {
        setRes((prevRes) => ({ data: null, error: null, isLoading: true }));
        let data = await fetch(
          process.env.REACT_APP_API_URL + `/api/${overrideUrl || url}`,
          {
            method: method,
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              ...headers,
            },
            body: JSON.stringify(payload),
          }
        );
        if (data.ok) {
          let response;
          try {
            response =
              responseFormat === 'TEXT' ? await data.text() : await data.json();
          } catch (error) {
            response = true;
          }
          setRes((prevRes) => ({
            ...prevRes,
            isLoading: false,
            data: response,
          }));
        } else {
          const errorMessage = await data.text();
          setRes((prevRes) => ({
            ...prevRes,
            isLoading: false,
            error: errorMessage,
          }));
          throw new Error(errorMessage);
        }
      } catch (err) {
        console.log(err);
      }
    },
    [url, headers, payload, method]
  );

  return [res, callAPI];
};

export default useFetch;
