import React, { useState, useContext } from 'react';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
import { getGrammar } from '../../core/helperFuncs/grammar';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Context } from '../../../context';
const SummaryTwo = () => {
  const [res] = useGetData('practice2-2');
  const [res2] = useGetData('top-5');
  const context = useContext(Context);

  return (
    <>
      <div className='intro-action'>
        <p>
          Congratulations! You have completed Module 2. Take a week or two to
          practice what you learned before moving on to Module 3! As a reminder
          – here is your at-home practice for praise and rewards:
        </p>
      </div>
      <div className='question-wrapper'>
        <ol className='summary'>
          <li>
            Continue to spend at least 15 minutes every day in child-centered
            time. This activity can include reading with your child.
          </li>
          <li>
            {!res2.data ? (
              <>
                Looks like you haven't picked labeled praise items to work on
                yet!{' '}
                <Link
                  to={'/course/module2/timeline/attention-behavior/practice'}
                  onClick={() => {
                    context.setCurSectionIndex(2);
                    context.setCurModLink(
                      '/course/module2/timeline/attention-behavior/practice'
                    );
                    context.setCurSlide(6);
                  }}
                >
                  Click here to choose some!
                </Link>
              </>
            ) : (
              <>
                Use labeled praise every time you see your child:
                <ul>
                  {res2.data.map((item, index) => (
                    <li style={{ color: '#333b70' }}>{item}</li>
                  ))}
                </ul>
              </>
            )}
          </li>
          <li>
            Praise another adult (for example, your partner, child’s day care
            teacher, or a coworker) for something he or she did well.
          </li>
          <li>
            {!res.data ? (
              <>
                Looks like you haven't picked a challenging behavior to work on
                yet!{' '}
                <Link
                  to={'/course/module2/timeline/reward-behavior/activity1'}
                  onClick={() => {
                    context.setCurSectionIndex(3);
                    context.setCurModLink(
                      '/course/module2/timeline/attention-behavior/practice'
                    );
                    context.setCurSlide(1);
                  }}
                >
                  Click here to choose one!
                </Link>
              </>
            ) : (
              <>
                In the module activity, you chose{' '}
                <span className='underline'>
                  {res.data.map((item, index) => (
                    <span>{item + getGrammar(index, res.data.length)}</span>
                  ))}{' '}
                </span>{' '}
                as challenging behaviors that you are working on. Develop a
                reward program or reward to help your child.
              </>
            )}
          </li>
        </ol>
      </div>
      <p className='padding-1 note'>
        <b>NOTE ABOUT PRACTICE:</b> When you are making your plan to complete
        the practice, think about things that might get in the way of spending
        child-centered time with your child, using labeled praise, or starting a
        reward program. Try to come up with a plan to overcome these barriers.
      </p>
    </>
  );
};

export default SummaryTwo;
