import React, { useEffect, useState, useContext } from 'react';
import { useFetch } from '../../../customHooks/useFetchExample';
import { handlePracticeGet } from '../../core/helperFuncs/handlePracticeGet';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { Context } from '../../../context';

const SummaryOne = () => {
  const context = useContext(Context);
  const [res] = useGetData('practice1-1');

  return (
    <>
      <div className='intro-action'>
        <p>
          Congratulations! You have completed Module 1. Take a week or two to
          practice what you learned before moving on to Module 2! As a reminder
          – here is your at-home practice for child- centered time and :
        </p>
      </div>
      <div className='question-wrapper'>
        <ol className='summary'>
          <li>
            Spend at least 15 minutes of child-centered time with your
            child(ren) each day. Watch how they react to this time with you!
          </li>
          <li>
            Try <b>descriptive commenting</b> at least once.
          </li>
          <li>
            {!res.data ? (
              <>
                Looks like you haven't picked a routine yet!{' '}
                <Link
                  to={'/course/module1/timeline/reading-routine/plan-it'}
                  onClick={() => {
                    context.setCurSectionIndex(3);
                    context.setCurModLink(
                      '/course/module1/timeline/reading-routine/plan-it'
                    );
                    context.setCurSlide(2);
                  }}
                >
                  Click here to choose one!
                </Link>
              </>
            ) : (
              <>
                You picked to practice{' '}
                <span className='underline'>{res.data}</span> as a routine,
                practice it regularly.
              </>
            )}
          </li>
        </ol>
      </div>
      <p className='padding-1 note'>
        <b>NOTE ABOUT PRACTICE:</b> When you are making your plan to practice,
        think about things that might get in the way of spending child-centered
        time with your child and starting new routines. For example, time, work
        schedule, school, other responsibilities. Try to come up with a plan to
        overcome these barriers.
      </p>
    </>
  );
};

export default SummaryOne;
