import React from 'react';
import Slider from './Slider';
import {
  findPracticeAnswer,
  findPracticeAssignmentAnswer,
} from '../../../assets/helperFuncs';
import AdminPracticeChecklist from './AdminPracticeChecklist';
import { it } from 'date-fns/locale';

const PracticeAssignment = ({ practiceItems, titleNames }) => {
  const practiceReviewCards = [
    [
      {
        question:
          'Spend at least 15 minutes of child-centered time with your child(ren) each day. Watch how they react to this time with you!',
      },
      { question: `Try descriptive commenting at least once.` },
      {
        question: (
          <span>
            You picked to practice{' '}
            {findPracticeAssignmentAnswer(practiceItems, 'practice1-1')} as a
            routine, practice it regularly.
          </span>
        ),
      },
    ],
    [
      {
        question:
          'Continue to spend at least 15 minutes every day in child-centered time. This activity can include reading with your child.',
      },
      {
        question: (
          <span>
            Use labeled praise every time you see your child:{' '}
            {findPracticeAssignmentAnswer(practiceItems, 'top-5')}
          </span>
        ),
      },
      {
        question:
          'Praise another adult (for example, your partner, child’s day care teacher, or a coworker) for something he or she did well.',
      },
      {
        question: (
          <span>
            In the module activity, you chose{' '}
            {findPracticeAssignmentAnswer(practiceItems, 'practice2-2')} as
            challenging behaviors that you are working on. Develop a reward
            program or reward to help your child.
          </span>
        ),
      },
    ],
    [
      {
        question:
          'Continue to spend at least 15 minutes every day in child-centered time. This activity can include reading with your child.',
      },
      {
        question: (
          <span>
            Review the list of household rules you made :{' '}
            {findPracticeAssignmentAnswer(practiceItems, 'ds-practice-1')}.
            Reduce the number of instructions and commands to those things that
            are on your list of Household Rules.
          </span>
        ),
      },
      {
        question:
          'Use do statements and warning statements. Make sure you keep it simple and clear!',
      },
    ],
    [
      {
        question:
          'Continue to spend at least 15 minutes every day in child-centered time. This activity can include reading with your child.',
      },
      { question: 'Give at least one natural if/then statement.' },
      { question: 'Give at least one logical if/then statement.' },
      { question: 'Give at least one when/then statement.' },
      {
        question: (
          <span>
            Look at the list of behaviors that you thought you could ignore.{' '}
            {findPracticeAssignmentAnswer(practiceItems, 'ignore-TYN-1a')}: Pick
            one of the behaviors that you think you can ignore. Then ignore the
            misbehavior every time you see it happen.
          </span>
        ),
      },
    ],
    [
      {
        question:
          'Continue to spend at least 15 minutes every day in child-centered time. This activity can include reading with your child.',
      },
      {
        question: (
          <span>
            You picked{' '}
            {findPracticeAssignmentAnswer(practiceItems, 'practice-to1')} as the
            behavior that you want to stop with a time-out (remember, these
            behaviors should be misbehaviors that don’t stop with a simple,
            clear do statement and is specific and clear to the child).
          </span>
        ),
      },
      {
        question: (
          <span>
            Plan ahead where you will have your child take a time-out. Make sure
            it is a safe place where you can see the child and they will not be
            distracted.{' '}
            {findPracticeAssignmentAnswer(practiceItems, 'mod-5-pr-1')}{' '}
          </span>
        ),
      },
      {
        question:
          'Explain time-out to your child. Make sure you pick a time to explain time-outs when you and your child are calm.',
      },
      {
        question:
          'Use time-out the next time your child does the misbehavior. Make sure you end the time-out on a positive note.',
      },
    ],
    [
      {
        question:
          'Continue to spend at least 15 minutes every day in child-centered time. This activity can include reading with your ',
      },
      {
        question: (
          <span>
            Look at the list you made of things that leave you feeling really
            stressed as a parent.{' '}
            {findPracticeAssignmentAnswer(practiceItems, 'p-practice-2')} Pick
            one of the things from this list and come up with a plan for
            managing the stress.
          </span>
        ),
      },
      {
        question:
          'Use one or more of the stress reduction strategies: Take 5 Breathing Exercise, Planning Ahead, Parent Time-Out, Mind Jars for Calming the Mind, and Reframing Affirmations',
      },
      {
        question: (
          <span>
            Pick a problem at home or at work that you want to work on using the
            steps to problem- solving. Write the problem below in a positive
            way: {findPracticeAssignmentAnswer(practiceItems, 'mod-6-pr-1')},
            then use the Guidelines for Problem-Solving to try and solve this
            problem.
          </span>
        ),
      },
    ],
  ];

  const completedReviewCards = titleNames
    .map((item, index) => {
      if (item.date) {
        return { questions: practiceReviewCards[index], date: item.date };
      } else {
        return '';
      }
    })
    .filter((item) => item !== '')
    .map((item, index) => (
      <AdminPracticeChecklist
        questionArray={item.questions}
        module={index + 1}
        date={item.date}
        practiceAssignment={true}
      />
    ));

  return <Slider slides={completedReviewCards} practiceAssignment={true} />;
};

export default PracticeAssignment;
