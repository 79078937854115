const postPractiveReview = async (payload) => {
  const bearerToken = `Bearer ${localStorage.getItem('token')}`;
  try {
    let data = await fetch(
      process.env.REACT_APP_API_URL + `/api/PracticeReview`,
      {
        method: 'POST',
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: bearerToken,
        },
        body: JSON.stringify(payload),
      }
    );
    if (!data.ok) {
      throw new Error();
    } else {
    }
  } catch (err) {
    console.log(err);
  }
};

export default postPractiveReview;
