import React, { useState, useEffect, useRef, useContext } from 'react';
import { Checkbox } from './Checkbox';
import AddableTextInput from './AddableTextInput';
import { Context } from '../../context';
import { handleSubmit as onSubmit } from '../core/helperFuncs/handleSubmit';
import { useGetData } from '../core/helperFuncs/handlePracticeGet';
import { handlePracticeSubmit } from '../core/helperFuncs/handlePracticeSubmit';
export const CheckList = ({
  options,
  fillIn,
  max,
  className,
  updateShowBadge,
  identifier,
  submitID,
}) => {
  const [other, setOther] = useState('');
  const [checkBoxItems, setCheckBoxItems] = useState(() =>
    options.map((item) => ({ name: item, checked: false }))
  );

  const [selectedIgnoreOptions, setIgnoreOptions] = useState([]);

  const context = useContext(Context);
  const [error, setError] = useState('');
  const [res, setRes] = useGetData(submitID);

  useEffect(() => {
    if (res.data) {
      setCheckBoxItems(
        checkBoxItems.map((item) => {
          if (res.data.includes(item.name)) {
            return { ...item, checked: true };
          } else {
            return item;
          }
        })
      );
    }
  }, [res]);

  const updateCheckbox = (option, index) => {
    if (updateShowBadge) {
      updateShowBadge();
    }
    setCheckBoxItems((prevState) =>
      prevState.map((item, currentIndex) => {
        if (index === currentIndex) {
          if (
            checkBoxItems.filter((item) => (item.checked ? item.name : false))
              .length >
              max - 1 &&
            !item.checked
          ) {
            setError(`Please pick your top ${max}!`);
            return item;
          } else {
            setError('');
            return { ...item, checked: !item.checked };
          }
        } else {
          return item;
        }
      })
    );
    // check to see if it's for ignore activity
    if (identifier && identifier == 'ig-activity') {
      // search to see if the item selected is already in the array. if it's not it will return -1
      const checkIndex = selectedIgnoreOptions.findIndex(
        (nameInArray) => nameInArray == option.name
      );
      if (
        checkBoxItems.filter((item) => (item.checked ? item.name : false))
          .length >
          max - 1 &&
        !option.checked
      ) {
        setError(`Please pick your top ${max}!`);
      } else {
        if (checkIndex === -1 && option.checked === false) {
          setIgnoreOptions((prevState) => [...prevState, option.name]);
          context.setIgnoreOptionsContext([
            ...context.state.selectedIgnoreOptionsContext,
            option.name,
          ]);
        } else if (checkIndex !== -1 && option.checked === true) {
          setIgnoreOptions((prevState) =>
            prevState.filter((item) => item !== option.name)
          );
          context.setIgnoreOptionsContext(
            context.state.selectedIgnoreOptionsContext.filter(
              (item) => item !== option.name
            )
          );
        }
      }
    }
  };

  const handleChange = (e) => setOther(e.target.value);

  const handleAdd = (e) => {
    if (other) {
      setCheckBoxItems((prevState) => [
        ...prevState,
        { name: other, checked: false },
      ]);
      setOther('');
    } else {
      setError('Input cannot be empty');
    }
  };

  const getChecked = () =>
    checkBoxItems.filter((item) => item.checked).map((item) => item.name);

  return (
    <form
      className='select-section'
      id={submitID}
      onSubmit={(e) => {
        e.preventDefault();
        handlePracticeSubmit(e.target.id, getChecked());
      }}
    >
      {checkBoxItems.map((item, index) => (
        <Checkbox
          type={'checkbox'}
          key={item.name}
          isChecked={item.checked}
          checkHandler={() => updateCheckbox(item, index)}
          index={index}
          label={item.name}
        ></Checkbox>
      ))}
      {fillIn && (
        <>
          <AddableTextInput
            value={other}
            handleChange={handleChange}
            placeholder={fillIn.instruct}
            handleSubmit={handleAdd}
            name={'Add'}
          />
          <span className='error'>{error}</span>
        </>
      )}
    </form>
  );
};
