import React from "react";
import "./addableTextInput.scss";
const AddableTextInput = ({
  value,
  handleChange,
  placeholder,
  handleSubmit,
  editable = true,
  name,
}) => {
  return (
    <div className="flex-wrap">
      <div className="select-item-wrap">
        {editable ? (
          <input
            type={"text"}
            className="other-section"
            value={value ? value : ""}
            onChange={handleChange}
            placeholder={placeholder}
          ></input>
        ) : (
          <p className="input-value">{value}</p>
        )}
        <button onClick={handleSubmit}>{name}</button>
      </div>
    </div>
  );
};

export default AddableTextInput;
