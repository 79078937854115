import React, { useEffect, useContext } from 'react';
// import "../handouts/handouts.scss";
import './badges.scss';
import { Context } from '../../../context';
import useStore from '../../../customHooks/useStore';

function ActivityBadge({ showBadge, dispatch, ...props }) {
  const context = useContext(Context);
  const { language } = useStore((state) => {
    return {
      language: state.language,
    };
  });

  useEffect(() => {
    let timeout;
    if (showBadge === true) {
      context.addCompletedBadge(props.content);
      timeout = setTimeout(() => {
        dispatch({ type: 'hide' });
      }, 4000);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [showBadge]);

  return (
    <>
      {props.content && (
        <div className='badge-container'>
          <div className='badge-wrapper'>
            <div className={`badge ${showBadge ? 'open' : 'closed'}`}>
              <h2 style={{ fontSize: '1.4rem' }}>You Earned a Badge!</h2>
              <img
                src={
                  process.env.PUBLIC_URL + language === 'en'
                    ? `/badges/${props.content}.png`
                    : `/badges/${props.content}sp.png`
                }
                alt='this badge'
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ActivityBadge;
