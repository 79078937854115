import React from 'react';
import practiceProps1 from '../../../../../assets/course/practice-checklist/module-1';
import practiceProps2 from '../../../../../assets/course/practice-checklist/module-2';
import practiceProps3 from '../../../../../assets/course/practice-checklist/module-3';
import practiceProps4 from '../../../../../assets/course/practice-checklist/module-4';
import practiceProps5 from '../../../../../assets/course/practice-checklist/module-5';
import practiceProps6 from '../../../../../assets/course/practice-checklist/module-6';
import { findPracticeAnswer } from '../../../assets/findPracticeAnswer';
import AdminPracticeChecklist from './AdminPracticeChecklist';
import Slider from './Slider';
const PracticeReviews = ({ practiceReviewArray, practiceItems }) => {
  let questions = [
    practiceProps1.map((item) =>
      item.name === 'practiceRev1-5'
        ? {
            ...item,
            question: `Start a new routine with my child, it was: ${
              findPracticeAnswer(practiceItems, 'practice1-1') || 'Not Answered'
            }`,
          }
        : { ...item }
    ),
    practiceProps2.map((item) =>
      item.name === 'practiceRev2-6'
        ? {
            ...item,
            question: `Start a new routine with my child, it was: ${
              findPracticeAnswer(practiceItems, 'practice2-2') || 'Not Answered'
            }`,
          }
        : { ...item }
    ),
    practiceProps3,
    practiceProps4,
    practiceProps5.map((item) =>
      item.name === 'practiceRev5-6'
        ? {
            ...item,
            question: `Use time-out when my child: ${
              findPracticeAnswer(practiceItems, 'practice-to1') ||
              'Not Answered'
            }`,
          }
        : { ...item }
    ),
    practiceProps6,
  ];

  questions = practiceReviewArray.map((item) => questions[item.module - 1]);

  const addAnswer = practiceReviewArray.map((item, index) => {
    let parsedValue = JSON.parse(practiceReviewArray[index].value);
    return {
      ...item,
      value: parsedValue,
    };
  });

  const questionWithAnswers = questions.map((item, index) => {
    return item.map((question) => {
      let answer = addAnswer[index].value[question.name];
      if (Array.isArray(answer)) {
        answer = answer.join(', ');
      }
      return {
        ...question,
        answer: answer,
      };
    });
  });

  const slideList = questionWithAnswers.map((item, index) => (
    <AdminPracticeChecklist
      questionArray={item}
      module={practiceReviewArray[index].module}
      date={practiceReviewArray[index].date}
    />
  ));

  return <Slider slides={slideList} />;
};

export default PracticeReviews;
