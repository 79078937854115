import React, { useEffect, useState } from "react";
import "./PlanIt.scss";

export const PracticeWrapper = ({ content, children }) => {
  return (
    <div className="plan-it-wrap">
      {content ? (
        <div className={`${content.wrapName}`}>{children}</div>
      ) : (
        <></>
      )}
    </div>
  );
};
