import React, { useState } from 'react';
import PracticeChecklist from './PracticeChecklist';
import usePractiveReview from '../../assets/helpers/usePractiveReview';
import NotCompletedResponse from './NotCompletedResponse';
import CompletionQuestionnaire from './CompletionQuestionnaire';
import ActivityBadge from '../shared/badges/ActivityBadge';
import { useBadge } from '../../customHooks/useBadge';
import './PractieChecklistWrapper.scss';

export const PractieChecklistWrapper = ({ module, practiceBadge }) => {
  const [showChecklist, setShowChecklist] = useState(false);
  const [submitted, setSubmitted] = useState(false);
  const [formArr, setFormArr] = useState([]);
  const [{ showBadge }, dispatch] = useBadge(false);
  const dataState = usePractiveReview(module);

  // ../../assets/course/practice-checklist/module-6

  import(`../../assets/course/practice-checklist/module-${module}`).then(
    (problems) => setFormArr(problems.default)
  );
  return (
    <section>
      {showChecklist !== 'YES' && (
        <section className='ready-to-complete'>
          {!showChecklist && !submitted && (
            <CompletionQuestionnaire
              modNum={module}
              confirmAction={() => setShowChecklist('YES')}
              denyAction={() => setShowChecklist('NO')}
              completed={dataState}
            />
          )}
          {submitted && (
            <p className='submission-response'>
              Great work! Keep putting what you learned into action to get the
              results you want!
            </p>
          )}
          {showChecklist === 'NO' && !submitted && <NotCompletedResponse />}
        </section>
      )}
      {showChecklist === 'YES' && !submitted && (
        <PracticeChecklist
          module={module}
          formArr={formArr}
          submitButton={'Submit'}
          setSubmitted={() => {
            dispatch({ type: 'show', payload: practiceBadge });
            setSubmitted(true);
            setShowChecklist(false);
          }}
        />
      )}
      <ActivityBadge
        showBadge={showBadge}
        dispatch={dispatch}
        content={practiceBadge}
      />
    </section>
  );
};
