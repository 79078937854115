import { createContext, useContext } from 'react';
import useFetch from './customHooks/useFetch';

const AdminInfoContext = createContext();

function AdminInfoProvider({ children }) {
  const [res, callAPI] = useFetch();
  return (
    <AdminInfoContext.Provider value={{ res, callAPI }}>
      {children}
    </AdminInfoContext.Provider>
  );
}

function useAdminInfo() {
  const context = useContext(AdminInfoContext);
  if (context === undefined) {
    throw new Error("useAdminInfo' must be used in AdminProvider");
  }
  return context;
}

export { AdminInfoProvider, useAdminInfo };
