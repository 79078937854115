const getPDF = async (num) => {
  const bearerToken = `Bearer ${localStorage.getItem('token')}`;
  const url = num ? `/api/certbuilder/${num}` : `/api/certbuilder`;

  try {
    let data = await fetch(process.env.REACT_APP_API_URL + url, {
      method: 'POST',
      mode: 'cors',

      headers: {
        'Content-Type': 'application/blob',
        Authorization: bearerToken,
      },
    });
    const response = await data.blob();
    if (!data.ok) {
      throw new Error();
    } else {
      return URL.createObjectURL(response);
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export default getPDF;
