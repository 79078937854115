import React from 'react';
import { FillIn } from './FillIn';

const practiceProps = [
  {
    question: 'Spend at least 15 minutes of child-centered time every day:',
    name: 'practiceRev5-1',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'I was able to spend child-centered time with my child:',
    name: 'practiceRev5-2',
    type: 'dropdown',
    options: ['Everyday', 'Almost every day', 'A few days', 'Not at all'],
  },
  {
    question: 'What were some of the child centered activities that you did?',
    name: 'practiceRev5-3',
    type: 'dropdown',
    options: [
      'Read',
      'Played the child’s choice of games',
      'Danced',
      'Sang',
      'Other',
    ],
  },
  {
    question: 'Explain time-out to my child.',
    name: 'practiceRev5-4',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: (
      <FillIn
        question={'Use time-out when my child:'}
        dataVarName='practice-to1'
      />
    ),
    name: 'practiceRev5-6',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'Follow the 8 Keys to Effective Discipline.',
    name: 'practiceRev5-7',
    type: 'radio',
    options: ['YES', 'NO'],
  },
];

export default practiceProps;
