import React, { useState, useReducer, useContext } from 'react';
import '.././PlanIt.scss';
import Question from '../Question';
import DropDown from '../../Form/DropDown';
import Outro from '../Outro';
import { Context } from '../../../context';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
import { handlePracticeSubmit } from '../../core/helperFuncs/handlePracticeSubmit';
export default function RRPPracticeOne({ content, submitID }) {
  const context = useContext(Context);
  const [res, setRes] = useGetData(submitID);
  const reducer = (state, action) => {
    setRes(false);
    switch (action.type) {
      case 'change-select':
        return { selectValue: action.payload, inputValue: '' };
      case 'change-input':
        return { selectValue: 'Other', inputValue: action.payload };
      default:
        return state;
    }
  };

  const [{ selectValue, inputValue }, dispatch] = useReducer(reducer, {
    selectValue: '',
    inputValue: '',
  });

  const [submitted, setSubmitted] = useState(false);

  const showOutro = () => {
    if (selectValue || inputValue) {
      setSubmitted(true);
    }
  };

  return (
    <>
      <form
        id={submitID}
        className='question-wrapper'
        onSubmit={(e) => {
          e.preventDefault();
          handlePracticeSubmit(
            e.target.id,
            inputValue ? inputValue : selectValue
          );
        }}
      >
        <Question>
          Pick a simple routine that you think is important and that you would
          like to start in your family.
        </Question>
        <div className='column'>
          <DropDown
            handleChange={(e) =>
              dispatch({ type: 'change-select', payload: e.target.value })
            }
            name='routine'
            value={
              res.data && content.select.includes(res.data)
                ? res.data
                : selectValue
            }
            optionsList={['Choose An Option', ...content.select, 'Other']}
          ></DropDown>
          <span>Or</span>
          <input
            placeholder='Add Your Own'
            className='fill-in-placeholder'
            onChange={(e) =>
              dispatch({ type: 'change-input', payload: e.target.value })
            }
            value={
              res.data && !content.select.includes(res.data)
                ? res.data
                : inputValue
            }
          ></input>
        </div>
      </form>

      <Outro>
        Start practicing this routine regularly! Pay attention to how your child
        responds to the routine.
      </Outro>
    </>
  );
}
