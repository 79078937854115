import { useEffect, useState } from 'react';

const usePractiveReview = (module) => {
  const [dataState, setDataState] = useState({ res: false, loading: false });
  useEffect(() => {
    setDataState({ res: false, loading: true });
    const getData = async () => {
      const bearerToken = `Bearer ${localStorage.getItem('token')}`;
      try {
        let data = await fetch(
          process.env.REACT_APP_API_URL + `/api/PracticeReview/${module}`,
          {
            method: 'GET',
            mode: 'cors',
            headers: {
              'Content-Type': 'application/json',
              Authorization: bearerToken,
            },
          }
        );
        if (!data.ok) {
          throw new Error();
        }
        const response = await data.json();
        setDataState({ res: JSON.parse(response.value), loading: false });
      } catch (err) {
        console.log(err);
        setDataState(false);
      }
    };
    getData();
  }, [module]);

  return dataState;
};

export default usePractiveReview;
