import React, { useContext, useState, useEffect } from 'react';
import { Context } from '../../../context';
import { ReactComponent as LightBulb } from '../../../assets/images/blocks1.svg';
import DevTipItems from './DevTipItems';

const Notifications = () => {
  const [showBelow, setShowBelow] = useState(false);
  const [missed, setMissed] = useState([]);
  const context = useContext(Context);
  const contextState = context.state;

  if (missed.length === 0 && showBelow) {
    setShowBelow(false);
  }

  const findMissedTips = () => {
    const holderArr = [];

    contextState.allDevTips.map((subArray, index) => {
      subArray
        .filter((item) => item.missed)
        .forEach((item) => {
          holderArr.push({ ...item, module: index });
        });
    });
    setMissed(holderArr);
  };

  useEffect(() => {
    findMissedTips();
  }, [contextState.allDevTips]);

  useEffect(() => {
    if (window.innerWidth < 970 && !contextState.isSidebarOpen && showBelow) {
      setShowBelow(false);
    }
  }, [contextState.isSidebarOpen]);

  return (
    <div
      className='icon notifications'
      onClick={(e) => {
        e.stopPropagation();
        setShowBelow((prev) => !prev);
      }}
    >
      <LightBulb />
      {missed.length !== 0 && (
        <div
          onClick={(e) => e.stopPropagation()}
          className={`belowHeader ${showBelow ? 'show' : 'hide'}`}
        >
          <div className='triangle'></div>
          <p className='header'>You Missed A Developmental Tip!</p>
          <div className='missed-tips'>
            {missed.map((noti, i) => {
              return <DevTipItems key={i} notification={noti} />;
            })}
          </div>
        </div>
      )}
      {missed.length !== 0 && (
        <div className='notifications-wrap'>
          <span className='notification-badge'>{missed.length}</span>
        </div>
      )}
    </div>
  );
};

export default Notifications;
