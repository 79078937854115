import React, { useContext, useEffect } from 'react';
import useStore from '../../../customHooks/useStore';
import { Context } from '../../../context';
import useFetch from '../../../customHooks/useFetch';

function LanguageToggle() {
  const context = useContext(Context);
  const { language, setLanguage } = useStore((state) => ({
    language: state.language,
    setLanguage: state.setLanguage,
  }));
  const [_res, callAPI] = useFetch({
    url: `lang?lang=${language}`,
    method: 'PATCH',
    responseFormat: 'TEXT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  const [res, callGetAPI] = useFetch({
    url: `lang`,
    method: 'GET',
    responseFormat: 'TEXT',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem('token')}`,
    },
  });

  useEffect(() => {
    callGetAPI();
  }, []);

  useEffect(() => {
    if (res.data) {
      setLanguage(res.data);
    }
  }, [res.data]);

  useEffect(() => {
    if (!context.state.isAuth.auth && !language) {
      window.Localize.setLanguage('en');
    }
  }, [language]);

  const handleClick = (lang) => {
    setLanguage(lang);
    if (context.state.isAuth.auth) {
      callAPI(`lang?lang=${lang}`);
    }
  };

  return (
    <div className='toggle'>
      <button
        className={`toggle-section ${language === 'en' && 'active'}`}
        onClick={() => handleClick('en')}
      >
        <span>English</span>
      </button>
      <button
        className={`toggle-section ${language === 'es' && 'active'}`}
        onClick={() => handleClick('es')}
      >
        <span>Español</span>
      </button>
    </div>
  );
}

export default LanguageToggle;
