import React from 'react';

export const FillIn = ({ question }) => {
  return (
    <>
      {question}
      <span style={{ textDecoration: 'underline' }}>Reading Before Bed</span>
    </>
  );
};

const practiceProps = [
  {
    question: 'Spend at least 15 minutes of child-centered time every day:',
    name: 'practiceRev6-1',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'I was able to spend child-centered time with my child:',
    name: 'practiceRev6-2',
    type: 'dropdown',
    options: ['Everyday', 'Almost every day', 'A few days', 'Not at all'],
  },
  {
    question: 'What were some of the child centered activities that you did?',
    name: 'practiceRev6-3',
    type: 'dropdown',
    options: [
      'Read',
      'Played the child’s choice of games',
      'Danced',
      'Sang',
      'Other',
    ],
  },
  {
    question:
      'Pick one of the things that cause me stress and make a plan for reducing stress.',
    name: 'practiceRev6-4',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'Here are the strategies I used (check all that apply)',
    name: 'checkGroup',
    type: 'checkbox',
    options: [
      'Take 5 Breathing Exercise',
      'Planning Ahead',
      'Parent Time-Out',
      'Mind Jars for Calming the Mind',
      'Reframing',
      'Affirmations',
    ],
  },
  {
    question: 'Use the Guidelines for Problem-Solving to solve a problem.',
    name: 'practiceRev6-6',
    type: 'radio',
    options: ['YES', 'NO'],
  },
];

export default practiceProps;
