import React from 'react';
import styled from 'styled-components';
import GenStyles from '../../../GenStyles';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
const S = {
  H2: styled.h2`
    font-size: 1.5rem;
    text-align: left;
    margin-bottom: 1rem 0;
  `,
  //users
  User: styled.div`
    padding: 1rem;
    border-top: #378abd solid 2px;
    border-bottom: #378abd solid 2px;
    display: flex;
    align-items: left;
    gap: 2rem;
    margin-top: 1rem;
  `,
  UserItemWrapper: styled.div`
    align-items: flex-start;
    display: flex;
    flex-direction: column;
    font-size: 1rem;
    @media (max-width: 768px) {
      display: ${(props) => (props.hide ? 'none' : 'flex')};
    }
  `,
  UserItemLabel: styled.span`
    font-weight: bold;
    padding-bottom: px;
    border-bottom: 2px solid;
    margin-bottom: 10px;
    color: #5f2c86;
  `,
  UserItem: styled.span`
    font-weight: 500;
  `,
};

const UserPreview = ({
  userID,
  lastLogin,
  modNum,
  sectionName,
  lastName,
  studyID,
  program,
}) => {
  return (
    <S.User>
      <S.UserItemWrapper>
        <S.UserItemLabel>User ID</S.UserItemLabel>
        <S.UserItem>{studyID}</S.UserItem>
      </S.UserItemWrapper>
      <S.UserItemWrapper>
        <S.UserItemLabel>Last Name</S.UserItemLabel>
        <S.UserItem>{lastName}</S.UserItem>
      </S.UserItemWrapper>
      <S.UserItemWrapper>
        <S.UserItemLabel>Last Login</S.UserItemLabel>
        <S.UserItem>{lastLogin}</S.UserItem>
      </S.UserItemWrapper>
      <S.UserItemWrapper hide>
        <S.UserItemLabel>Module</S.UserItemLabel>
        <S.UserItem>
          {modNum}
          {program === 'HEK' ? '' : '/6'}
        </S.UserItem>
      </S.UserItemWrapper>
      <S.UserItemWrapper hide>
        <S.UserItemLabel>Section</S.UserItemLabel>
        <S.UserItem>{sectionName}</S.UserItem>
      </S.UserItemWrapper>
      <GenStyles.UserDetailLink to={`/users/${userID}`}>
        Details
      </GenStyles.UserDetailLink>
    </S.User>
  );
};

export default UserPreview;
