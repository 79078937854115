import React, { useContext, useEffect, useState } from 'react';
import { Context } from '../../../context';
import './login.scss';
import { Redirect } from 'react-router-dom';
import { useForm } from '../../../customHooks/useForm';
import useFetch from '../../../customHooks/useFetch';
import Logo from '../../../assets/images/EZP_logo_8[22].png';
import SpanishLogo from '../../../assets/images/EZP_logo_ES3.png';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import LanguageToggle from '../header/LanguageToggle';
import LoginHeader from './LoginHeader';
import useStore from '../../../customHooks/useStore';
function Login() {
  const { language, setLanguage } = useStore((state) => ({
    language: state.language,
    setLanguage: state.setLanguage,
  }));
  const [formState, handleChange, setState] = useForm();
  const [redirect, setRedirect] = useState(false);
  const [error, setError] = useState('');
  const history = useHistory();
  const [res, callAPI] = useFetch({
    url: 'login',
    method: 'POST',
    payload: formState,
    responseFormat: 'JSON',
  });
  const context = useContext(Context);
  useEffect(() => {
    if (res.error) {
      setState({});
      setError("The username or password you've entered is incorrect");
    }
    if (res.data) {
      if (res.data.program === 'EZP' || res.data.role === 'C') {
        localStorage.setItem('name', res.data.name);
        localStorage.setItem('initialVideo', res.data.initialVideo);
        context.login(res.data.token, true);
        if (res.data.role === 'C') {
          localStorage.setItem('coach', true);
          localStorage.setItem('program', res.data.program);
          context.setIsAdmin(true, res.data.program);
        }

        history.push('/course/overview');
      } else {
        setError('Oops looks like that is a Health-E Kids Log in...');
      }
    }
  }, [res, setState]);

  const submitLogin = (e) => {
    e.preventDefault();
    callAPI();
  };
  return (
    <div className='auth-wrapper'>
      <LoginHeader logedInOrSignUp='login' />
      <section>
        <img
          src={language === 'es' ? SpanishLogo : Logo}
          alt='logo'
          className='logo'
        />
        <div className='login-wrap'>
          <div className='inner-wrap'>
            <h1>Sign In</h1>
            <form onSubmit={(e) => submitLogin(e)}>
              <label>
                <p>Username</p>
                <input
                  required
                  name='username'
                  type='text'
                  value={formState['username'] || ''}
                  onChange={(e) => {
                    handleChange(e);
                    setError('');
                  }}
                />
              </label>
              <label>
                <p>Password</p>
                <input
                  required
                  value={formState['password'] || ''}
                  onChange={(e) => {
                    handleChange(e);
                    setError('');
                  }}
                  name='password'
                  type='password'
                />
              </label>
              {error && <p className='error'>{error}</p>}
              <div className='button-wrap'>
                {!res.loading && (
                  <button className='button' type='submit'>
                    Login
                  </button>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Login;
