import React, { useContext } from 'react';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { getGrammar } from '../../core/helperFuncs/grammar';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
import { Context } from '../../../context';
const SummaryFour = (props) => {
  const [res] = useGetData('ignore-TYN-1a');
  const context = useContext(Context);

  return (
    <>
      <div className='intro-action'>
        <p>
          Congratulations! You have completed Module 4. Take a week or two to
          practice what you learned before moving on to Module 5! As a reminder
          – here is your at-home practice for Setting Limits and Managing
          Behavior.
        </p>
      </div>
      <div className='question-wrapper'>
        <ol className='summary'>
          <li>
            Continue to spend at least 15 minutes every day in child-centered
            time. This activity can include reading with your child.
          </li>
          <li>Give at least one natural if/then statement.</li>
          <li>Give at least one logical if/then statement.</li>
          <li>Give at least one when/then statement.</li>
          <li>
            {!res.data ? (
              <>
                Looks like you haven't picked a list of behaviors to ignore yet!{' '}
                <Link
                  to={'/course/module4/timeline/ignore/ig-activity'}
                  onClick={() => {
                    context.setCurSectionIndex(4);
                    context.setCurModLink(
                      '/course/module4/timeline/ignore/ig-activity'
                    );
                    context.setCurSlide(1);
                  }}
                >
                  Click here to make one!
                </Link>
              </>
            ) : (
              <>
                Look at the list of behaviors that you thought you could ignore.
                <ul>
                  {res.data.map((item) => (
                    <li style={{ color: '#333b70' }}>{item}</li>
                  ))}
                </ul>
                Pick one of the behaviors that you think you can ignore. Then
                ignore the misbehavior every time you see it happen.
              </>
            )}
          </li>
        </ol>
        <div className='selected-ignore-options'>
          <ul>
            {/* {selectedIgnoreOptionsContext ? (
              selectedIgnoreOptionsContext.map((item) => (
                <li key={item}>{item}</li>
              ))
            ) : (
              <div></div>
            )} */}
          </ul>
        </div>
      </div>
      <p className='padding-1 note'>
        <b>NOTE ABOUT PRACTICE:</b> When you are making your plan to complete
        the practice, think about things that might get in the way of using
        if/then statements, when/then statements and ignoring. Try to come up
        with a plan to overcome these barriers.
      </p>
    </>
  );
};

export default SummaryFour;
