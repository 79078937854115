import React, { useState, Fragment } from 'react';
import '../practice/PlanIt.scss';
import './activity.scss';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import './StatementMatch.scss';
import { useBadge } from '../../customHooks/useBadge';
import ActivityBadge from '../shared/badges/ActivityBadge';
const items = [
  { id: '1', content: 'then you will be hungry', key: 4 },
  { id: '2', content: 'then you will get cold', key: 2 },
  { id: '3', content: 'then it could get stolen', key: 1 },
  { id: '4', content: 'then you will be tired', key: 3 },
];

const backendcolumns = {
  1: {
    name: 'If you leave your bike out',
    key: 1,
    items: [],
  },
  2: {
    name: 'If you don’t put your coat on',
    key: 2,
    items: [],
  },
  3: {
    name: 'If you don’t take a nap',
    key: 3,
    items: [],
  },
  4: {
    name: 'If you don’t eat your dinner',
    key: 4,
    items: [],
  },
  5: {
    name: 'Answers',
    items: items,
  },
};

const onDragEnd = (
  result,
  columns,
  setColumns,
  setComplete,
  dispatch,
  badge
) => {
  if (!result.destination) return;
  const { source, destination } = result;
  if (source.droppableId !== destination.droppableId) {
    const sourceColumn = columns[source.droppableId];
    const destinationColumn = columns[destination.droppableId];
    const sourceItems = [...sourceColumn.items];
    const destItems = [...destinationColumn.items];
    const [removed] = sourceItems.splice(source.index, 1);
    if (removed.key === destinationColumn.key) {
      dispatch({ type: 'show', payload: badge });
    }
    destItems.splice(destination.index, 0, {
      ...removed,
      answer:
        removed.key === destinationColumn.key
          ? 'correct'
          : destinationColumn.key
          ? 'incorrect'
          : '',
    });
    const updatedColumns = {
      ...columns,
      [source.droppableId]: {
        ...sourceColumn,
        items: sourceItems,
      },
      [destination.droppableId]: {
        ...destinationColumn,
        items: destItems,
      },
    };
    setColumns(updatedColumns);
    setComplete(checkColumnsComplete(updatedColumns));
  } else {
    const column = columns[source.droppableId];
    const coppiedItems = [...column.items];
    const [removed] = coppiedItems.splice(source.index, 1);
    coppiedItems.splice(destination.index, 0, removed);
    setColumns({
      ...columns,
      [source.droppableId]: {
        ...column,
        items: coppiedItems,
      },
    });
  }
};

const returnColor = (dragging, answer) => {
  if (dragging) {
    return '#333b70';
  }
  if (answer === 'correct') {
    return 'rgba(38, 226, 38, 0.804)';
  }
  if (answer === 'incorrect') {
    return '#ff4d4d';
  } else {
    return '#388abc';
  }
};

const draggable = (column) => {
  return column.items.map((item, index) => {
    return (
      <Draggable key={item.id} draggableId={item.id} index={index}>
        {(provided, snapshot) => {
          return (
            <div
              className='answer'
              ref={provided.innerRef}
              {...provided.dragHandleProps}
              {...provided.draggableProps}
              style={{
                userSelect: 'none',
                backgroundColor: returnColor(snapshot.isDragging, item.answer),
                ...provided.draggableProps.style,
              }}
            >
              <p>{item.content}</p>
            </div>
          );
        }}
      </Draggable>
    );
  });
};

const droppable = (id, column) => {
  return (
    <Droppable
      droppableId={id}
      key={id}
      isDropDisabled={
        column.items.length && column.name !== 'Answers' ? true : false
      }
    >
      {(provided, snapshot) => {
        return (
          <div
            className={
              column.name === 'Answers'
                ? 'answer-dropable'
                : 'question-dropable'
            }
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={{
              background: snapshot.isDraggingOver
                ? 'lightblue'
                : column.name === 'Answers'
                ? '#bbbbbb'
                : 'white',
              height: '100%',
            }}
          >
            {column.name === 'Answers' ? (
              <div className='answer-wrap'>{draggable(column)}</div>
            ) : (
              draggable(column)
            )}
            {provided.placeholder}
          </div>
        );
      }}
    </Droppable>
  );
};

const getAnswerObject = (columns) => {
  return Object.entries(columns).map(([id, column], index) => {
    if (index === Object.keys(columns).length - 1) {
      return droppable(id, column);
    }
  });
};

const checkColumnsComplete = (columns) => {
  let checkColumns = Object.values(columns);
  checkColumns.pop();

  return checkColumns.every(
    (column) => column.items[0] && column.items[0].answer === 'correct'
  );
};

const StatementMatch = ({ content, badge }) => {
  const [columns, setColumns] = useState(content.columns);
  const [complete, setComplete] = useState(false);
  const [{ showBadge }, dispatch] = useBadge(false);

  return (
    <section>
      <div className='instruction-wrapper'>
        <p>{content.intro1}</p>
        <p>{content.intro2}</p>
      </div>
      <div className='table-header'>
        <span>{content.columnHeaders[0]}</span>
        <span>{content.columnHeaders[1]}</span>
      </div>
      <DragDropContext
        onDragEnd={(result) =>
          onDragEnd(result, columns, setColumns, setComplete, dispatch, badge)
        }
        style={{ display: 'flex' }}
      >
        <div className='table'>
          {Object.entries(columns).map(([id, column], index) => {
            if (Object.keys(columns).length - 1 !== index) {
              return (
                <Fragment key={index}>
                  {column.name !== 'Answers' && (
                    <div className='question'>
                      <p>{column.name}</p>
                    </div>
                  )}
                  {droppable(id, column)}
                </Fragment>
              );
            }
          })}
        </div>
        {complete ? (
          <div className='completed-response'>
            <p>Great Job!</p>
          </div>
        ) : (
          getAnswerObject(columns)
        )}
      </DragDropContext>
      {content.outro1 && (
        <div className='instruction-wrapper outro-wrapper'>
          <p className='outro-item'>{content.outro1}</p>
          <p className='outro-item'>{content.outro2}</p>
        </div>
      )}
      <ActivityBadge
        showBadge={showBadge}
        content={badge}
        dispatch={dispatch}
      />
    </section>
  );
};

export default StatementMatch;
