import { useCallback, useState } from 'react';
import { Context } from '../../../context';
import { useContext } from 'react';

const useFetch = () => {
  const context = useContext(Context);
  const [res, setRes] = useState({
    data: false,
    error: false,
    isLoading: true,
  });

  const callAPI = useCallback(async ({ method, url }) => {
    try {
      let data = await fetch(process.env.REACT_APP_API_URL + `/api/${url}`, {
        method: method,
        mode: 'cors',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${localStorage.getItem('token')}`,
        },
      });
      if (data.ok) {
        try {
          const response = await data.json();

          setRes((prev) => ({
            ...prev,
            isLoading: false,
            data: response,
          }));
          return response;
        } catch (error) {
          return true;
        }
      } else {
        if (data.status === 401) {
          context.logout();
        }
        throw new Error();
      }
    } catch (err) {
      console.log(err);
      setRes((prev) => ({ ...prev, isLoading: false, error: true }));
      console.log(err);
      return false;
    }
  }, []);

  return [res, callAPI];
};

export default useFetch;
