import React, { useContext, useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import flow from '../../../assets/flow.json';
import { Context } from '../../../context';
import './sidebar.scss';
import { ReactComponent as BagOfTricksImage } from '../../../assets/images/header-icons/tricks.svg';
import { ReactComponent as ProfileImage } from '../../../assets/images/header-icons/profile.svg';
import Notifications from '../header/Notifications';
import LanguageToggle from '../header/LanguageToggle';

function SideBar() {
  const context = useContext(Context);

  const setLocation = () => {
    flow.forEach((item, index) => {
      if (window.location.pathname.includes(item.url)) {
        context.setSidebarModuleOpen(index - 1);

        item.links.forEach((link, i) => {
          if (window.location.pathname.includes(link.url)) {
            context.setCurSectionIndex(i);
          }
        });
      }
    });
  };

  useEffect(() => {
    setLocation();
    window.addEventListener('popstate', setLocation);
    return () => window.removeEventListener('popstate', setLocation);
  }, []);

  return (
    <>
      <div className='sidebar-group'>
        <div
          className={`sidebar-wrap ${
            context.state.isSidebarOpen ? 'open' : 'closed'
          }`}
        >
          <div className='link-wrap'>
            <div className='icons-box'>
              <div
                className='icon notifications'
                onClick={() => {
                  context.toggleMenu();
                  context.setCurSlide(0);
                }}
              >
                <Notifications />
              </div>
              <NavLink to={'/bag-of-tricks-page'}>
                <div
                  className='icon bag-of-tricks'
                  onClick={() => {
                    context.toggleMenu();
                    context.setCurSlide(0);
                  }}
                >
                  <BagOfTricksImage />
                </div>
              </NavLink>
              <NavLink to={'/profile'}>
                <div
                  className='icon profile'
                  onClick={() => {
                    context.toggleMenu();
                    context.setCurSlide(0);
                  }}
                >
                  <ProfileImage />
                </div>
              </NavLink>
              <LanguageToggle />
            </div>
            {flow.map((item, index) => {
              return (
                <div
                  key={index}
                  className={`${
                    context.state.disabledArray.includes(item.url)
                      ? 'link-disabled'
                      : ''
                  } ${
                    window.location.pathname.includes(item.url)
                      ? 'selected'
                      : ''
                  } nav-links`}
                >
                  <NavLink
                    className={`${
                      context.state.disabledArray.includes(item.url)
                        ? 'sidebar-disabled'
                        : ''
                    } module-link`}
                    to={item.links.length ? item.links[0].url : item.url}
                    onClick={() => {
                      if (item.title === 'Overview') {
                        context.toggleMenu();
                        context.setCurSectionIndex('Overview');
                      }
                      context.setCurSectionIndex(0);
                      context.setSidebarModuleOpen(index - 1);
                      context.setCurSlide(0);
                    }}
                  >
                    <p>{item.title}</p>
                  </NavLink>

                  {item.links ? (
                    <div className='subsection'>
                      {item.links.map((link, i) => {
                        return (
                          <div
                            key={i}
                            className={`${
                              index !== 0 &&
                              context.state.linkIndexes[index - 1] < i
                                ? 'link-disabled'
                                : ''
                            } subsection-block`}
                            id={link.url}
                          >
                            <NavLink
                              key={i}
                              to={link.url}
                              className={`${
                                window.location.pathname.includes(link.url)
                                  ? 'sub-selected'
                                  : ''
                              }`}
                              onClick={() => {
                                // context.setCurModLink(link.url);
                                context.setCurSectionIndex(i);
                                context.toggleMenu();
                                context.setCurSlide(0);
                              }}
                            >
                              <div
                                className={`${
                                  index !== 0 &&
                                  context.state.linkIndexes[index - 1] < i
                                    ? 'link-disabled'
                                    : ''
                                } subsection-links`}
                              >
                                {link.title}
                              </div>
                            </NavLink>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
    </>
  );
}

export default SideBar;
