import parseISO from 'date-fns/parseISO';
import format from 'date-fns/format';

//onSubmit needs to be made when we know where to send data
const AdminPracticeChecklist = ({
  questionArray,
  module,
  date,
  practiceAssignment,
}) => {
  return (
    <section className='checklist-wrapper'>
      <form>
        <div style={{ marginBottom: '1rem' }}>
          <h2>{`Module ${module}`}</h2>
          <b>Completed : {date ? format(parseISO(date), 'LL/dd/y') : ''}</b>
        </div>
        <div className='checklist-section question border-bottom'></div>
        {questionArray.map((item, index) => {
          return (
            <section key={index}>
              <p>
                <b>Question : </b>
                {item.question}
              </p>
              {!practiceAssignment && (
                <p>
                  <b>Answer : </b>
                  {item.answer ? (
                    item.answer
                  ) : (
                    <span style={{ color: 'red' }}>Not Answered</span>
                  )}
                </p>
              )}
            </section>
          );
        })}
      </form>
    </section>
  );
};

export default AdminPracticeChecklist;
