import React, { useEffect } from 'react';
import reactDom from 'react-dom';
import './Modal.scss';
import App from '../../../App';
import { ReactComponent as Close } from '../../../assets/images/close.svg';
import Timeline from '../../core/timeline/timeline';
function Modal(props) {
  const [domReady, setDomReady] = React.useState(false);

  useEffect(() => {
    if (document.getElementById('fade')) {
      setDomReady(true);
    }
  }, []);

  return domReady
    ? reactDom.createPortal(
        <>
          <div className={`modal-wrap ${props.show ? 'show-me' : 'hide-me'}`}>
            <div
              className='grey-cover'
              onClick={() => props.showModal(false)}
            ></div>
            <div className='content-wrap'>
              <div>
                <button
                  className='close'
                  onClick={
                    !props.overide
                      ? () => props.showModal(false)
                      : props.showModal
                  }
                >
                  <Close />
                </button>
                {props.children}
              </div>
            </div>
          </div>
        </>,
        document.getElementById('fade')
      )
    : null;
}

export default Modal;
