class AssetLoad {
  getTimeline(url, module) {
    const response = require(`../assets/course/Content/${module}/${url}.json`);
    return response;
  }

}

const AssetService = new AssetLoad();
Object.freeze(AssetService);
export default AssetService;
