const logoutFetch = async () => {
  const bearerToken = `Bearer ${localStorage.getItem('token')}`;
  try {
    let data = await fetch(process.env.REACT_APP_API_URL + `/api/logout`, {
      method: 'POST',
      mode: 'cors',

      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
      },
    });
    if (!data.ok) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
    return false;
  }
};

export default logoutFetch;
