import React, { useContext, useEffect, useState } from 'react';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { handlePracticeSubmit } from '../../core/helperFuncs/handlePracticeSubmit';
import { handlePracticeGet } from '../../core/helperFuncs/handlePracticeGet';
import { Context } from '../../../context';
// p-practice-2
const SummarySix = () => {
  const [res] = useGetData('p-practice-2');
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    handlePracticeGet('mod-6-pr-1').then((res) => {
      if (res.value) {
        setInputValue(JSON.parse(res.value));
      }
    });
  }, []);

  useEffect(() => {
    const delayDebounceFn = setTimeout(() => {
      handlePracticeSubmit('mod-6-pr-1', inputValue);
    }, 1000);

    return () => clearTimeout(delayDebounceFn);
  }, [inputValue]);

  const context = useContext(Context);
  return (
    <>
      <div className='intro-action'>
        <p>
          Congratulations! You have completed Module 6. Take a week or two to
          practice what you learned! As a reminder – here is your at-home
          practice for reducing stress and problem solving:
        </p>
      </div>
      <div className='question-wrapper'>
        <ol className='summary'>
          <li>
            Continue to spend at least 15 minutes every day in child-centered
            time. This activity can include reading with your child.
          </li>
          <li>
            {!res.data ? (
              <>
                Looks like you haven't made a list you made of things that leave
                you feeling really stressed as a parent!{' '}
                <Link
                  to={'/course/module6/timeline/parent-stress/ps-activity'}
                  onClick={() => {
                    context.setCurSectionIndex(1);
                    context.setCurModLink(
                      '/course/module6/timeline/parent-stress/ps-activity'
                    );
                    context.setCurSlide(1);
                  }}
                >
                  Click here to do that!
                </Link>
              </>
            ) : (
              <>
                Look at the list you made of things that leave you feeling
                really stressed as a parent.
                <ul>
                  {res.data.map((item) => (
                    <li style={{ color: '#333b70' }}>{item}</li>
                  ))}
                </ul>
                Pick one of the things from this list and come up with a plan
                for managing the stress.
              </>
            )}
          </li>
          <li>
            Use one or more of the stress reduction strategies: Take 5 Breathing
            Exercise, Planning Ahead, Parent Time-Out, Mind Jars for Calming the
            Mind, and Reframing Affirmations
          </li>
          <li className='flex-down'>
            <p>
              Pick a problem at home or at work that you want to work on using
              the steps to problem- solving. Write the problem below in a
              positive way:
            </p>
            <input
              className='fillIn-text'
              type={'text'}
              value={inputValue}
              onChange={(e) => setInputValue(e.target.value)}
            ></input>
            <p>
              then use the Guidelines for Problem-Solving to try and solve this
              problem.
            </p>
          </li>
        </ol>
      </div>
      <p className='padding-1 note'>
        <b>NOTE ABOUT PRACTICE:</b> When you are making your plan to complete
        the practice, think about things that might get in the way of using
        time-outs with your child. Try to come up with a plan to overcome these
        barriers.
      </p>
    </>
  );
};

export default SummarySix;
