import React from 'react';
import { FillIn } from './FillIn';

const practiceProps = [
  {
    question: 'Spend at least 15 minutes of child-centered time every day:',
    name: 'practiceRev2-1',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'I was able to spend child-centered time with my child:',
    name: 'practiceRev2-2',
    type: 'dropdown',
    options: ['Everyday', 'Almost every day', 'A few days', 'Not at all'],
  },
  {
    question: 'What were some of the child centered activities that you did?',
    name: 'practiceRev2-3',
    type: 'dropdown',
    options: [
      'Read',
      'Played the child’s choice of games',
      'Danced',
      'Sang',
      'Other',
    ],
  },
  {
    question:
      'Use labeled praise for a behavior I wanted to see more from my child.',
    name: 'practiceRev2-4',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'Use labeled praise with another adult in my life.',
    name: 'practiceRev2-5',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: (
      <FillIn
        question={'Started a reward program with my child for:'}
        dataVarName={'practice2-2'}
        type={'Array'}
      />
    ),
    name: 'practiceRev2-6',
    type: 'radio',
    options: ['YES', 'NO'],
  },
  {
    question: 'What was the reward?',
    name: 'practiceRev2-7',
    type: 'text',
  },
];

export default practiceProps;
