const submitSurvey = async (module, rating) => {
  const bearerToken = `Bearer ${localStorage.getItem('token')}`;
  try {
    let data = await fetch(process.env.REACT_APP_API_URL + `/api/eopratings`, {
      method: 'PUT',
      mode: 'cors',
      headers: {
        'Content-Type': 'application/json',
        Authorization: bearerToken,
      },
      body: JSON.stringify({ module: module + 1, rating }),
    });
    if (!data.ok) {
      throw new Error();
    }
  } catch (err) {
    console.log(err);
  }
};

export default submitSurvey;
