import React, { useContext, useEffect, useState } from 'react';
import { useGetData } from '../../core/helperFuncs/handlePracticeGet';
import { Context } from '../../../context';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';
import { handlePracticeSubmit } from '../../core/helperFuncs/handlePracticeSubmit';
import { handlePracticeGet } from '../../core/helperFuncs/handlePracticeGet';
const SummaryFive = () => {
  const [res] = useGetData('practice-to1');
  const context = useContext(Context);
  const [dropdownValue, setDropdownValue] = useState();

  useEffect(() => {
    handlePracticeGet('mod-5-pr-1').then((res) => {
      if (res.value) {
        setDropdownValue(JSON.parse(res.value));
      }
    });
  }, []);

  return (
    <>
      <div className='intro-action'>
        <p>
          Congratulations! You have completed Module 5. Take a week or two to
          practice what you learned before moving on to Module 6! As a reminder
          – here is your at-home practice for using time-outs:
        </p>
      </div>
      <div className='question-wrapper'>
        <ol className='summary'>
          <li>
            Continue to spend at least 15 minutes every day in child-centered
            time. This activity can include reading with your child.
          </li>
          <li>
            {!res.data ? (
              <>
                Looks like you have not picked a behavior that you want to stop
                with a time-out yet!{' '}
                <Link
                  to={'/course/module5/timeline/time-out1/practice'}
                  onClick={() => {
                    context.setCurSectionIndex(2);
                    context.setCurModLink(
                      '/course/module5/timeline/time-out1/practice'
                    );
                    context.setCurSlide(2);
                  }}
                >
                  Click here to choose one!
                </Link>
              </>
            ) : (
              <>
                You picked <span className='underline'>{res.data}</span> as the
                behavior that you want to stop with a time-out (remember, these
                behaviors should be misbehaviors that don’t stop with a simple,
                clear do statement and is specific and clear to the child).
              </>
            )}
          </li>
          <li>
            Plan ahead where you will have your child take a time-out. Make sure
            it is a safe place where you can see the child and they will not be
            distracted.
            <select
              style={{ margin: '.5rem 0' }}
              value={dropdownValue}
              onChange={(e) => {
                setDropdownValue(e.target.value);
                handlePracticeSubmit('mod-5-pr-1', e.target.value);
              }}
            >
              <option value={'Choose an Option'}>Choose an Option</option>
              <option value={'Chair'}>Chair</option>
              <option value={'Bedroom'}>Bedroom</option>
              <option value={'Couch'}>Couch</option>
              <option value={'A step'}>A step</option>
              <option value={'Corner of a room'}>Corner of a room</option>
              <option value={'Stool'}>Stool</option>
              <option value={'Hallway'}>Hallway</option>
              <option value={'Other'}>Other</option>
            </select>
          </li>
          <li>
            Explain time-out to your child. Make sure you pick a time to explain
            time-outs when you and your child are calm.
          </li>
          <li>
            Use time-out the next time your child does the misbehavior. Make
            sure you end the time-out on a positive note.
          </li>
        </ol>
      </div>
      <p className='padding-1 note'>
        <b>NOTE ABOUT PRACTICE:</b> When you are making your plan to complete
        the practice, think about things that might get in the way of using
        time-outs with your child. Try to come up with a plan to overcome these
        barriers.
      </p>
    </>
  );
};

export default SummaryFive;
