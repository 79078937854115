import React, { useContext, useEffect, useState, Fragment } from 'react';
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from 'react-router-dom';
import ProfilePage from '../../components/core/profile/ProfilePage.js';
import { Consumer, Context } from '../../context.js';
import SideBar from '../../components/core/sidebar/sidebar.jsx';
import Header from '../../components/core/header/header.jsx';
import Flow from '../../assets/flow.json';
import './page-content.scss';
import Module from '../../components/course/Module.jsx';
import Overview from '../../components/course/Overview/overview.jsx';
import flow from '../../assets/flow.json';
import ProgressRedirect from '../ProgressRedirect/ProgressRedirect.jsx';
import AllHandouts from '../../components/course/Overview/AllHandouts.js';
import { gsap } from 'gsap';
import RightProgress from '../../components/core/right-progress/RightProgress.jsx';
import BagOfTricksPage from '../../components/shared/bagOfTricksPage/bagOfTricksPage.jsx';
import Login from '../../components/core/login/login.jsx';
import SignUp from '../../components/core/login/SignUp.js';
import DetailPage from '../../components/admin-page/pages/AdminDetailPage.js';
import AdminPage from '../../components/admin-page/pages/AdminPage.js';
import ForgotUsernameOrPassword from '../../components/core/login/ForgotUsernameOrPassword.js';
import { AdminInfoProvider } from '../../components/admin-page/adminContext.js';
import { HeaderProvider } from '../../components/core/header/headerContext.js';
import ResetPassword from '../../components/core/login/ResetPassword.jsx';

const Routes = () => {
  const context = useContext(Context);

  useEffect(() => {
    context.getProgressData().then((result, err) => {
      if (!result) {
        return;
      }
      if (window.location.pathname) result = JSON.parse(result);

      const token = localStorage.getItem('token');
      context.login(token);
      // context.setSidebarModuleOpen(modindex + 1, modindex);

      if (localStorage.coach === 'true') {
        context.setIsAdmin(true, localStorage.getItem('program'));
      }
      if (!result) {
        context.logout();
      }
    });
  }, []);

  // if not authorized and is checking if it is authorized

  // if not authorized and it has completed checking if it should be authorized
  if (!context.state.isAuth.auth && !context.state.isAuth.loading) {
    return (
      <Router>
        <Switch>
          <Route path={'/sign-up'}>
            <SignUp />
          </Route>
          <Route path={'/login'}>
            <Login />
          </Route>
          <Route path={'/reset-password'}>
            <ForgotUsernameOrPassword />
          </Route>
          <Redirect from={'/'} to={'/login'} />
        </Switch>
      </Router>
    );
  }
  //logged In. Check if user is an admin and then to admin page if true. Go to normal user page if not
  else {
    return (
      <>
        {context.state.isAuth.auth ? (
          <Router>
            {!context.state.isAdmin.isAdmin ? (
              <HeaderProvider>
                <div>
                  <Header />
                  <SideBar />
                  <div className='course-material' id='course-material'>
                    {/* Module Content */}
                    <Switch>
                      <Route exact path={'/bag-of-tricks-page'}>
                        <BagOfTricksPage />
                      </Route>
                      <Route path={'/course/overview/handout/:id'}>
                        <AllHandouts />
                      </Route>
                      <Route path={'/profile'}>
                        <ProfilePage />
                      </Route>
                      <Route path={'/course/overview'}>
                        <Overview />
                      </Route>
                      <Route path={'/reset-password'}>
                        <ResetPassword />
                      </Route>
                      {Flow.map((item, i) => {
                        return (
                          <Route path={item.url}>
                            {item.type === 'Module' ? (
                              <Module
                                lastData={Flow[i - 1]}
                                nextData={Flow[i + 1]}
                                parentRoute={item.url}
                                data={item}
                                modIndex={i - 1}
                              />
                            ) : (
                              <Overview />
                            )}
                          </Route>
                        );
                      })}
                      <Redirect from={'/'} to={'/course/overview'} />
                    </Switch>
                  </div>
                </div>
              </HeaderProvider>
            ) : (
              <AdminInfoProvider>
                <Switch>
                  <Route path={'/users/:id'}>
                    <DetailPage />
                  </Route>
                  <Route path={'/admin'}>
                    <AdminPage />
                  </Route>
                  <Redirect from={'/'} to={'/admin'} />
                </Switch>
              </AdminInfoProvider>
            )}
          </Router>
        ) : (
          <div></div>
        )}
      </>
    );
  }
};

export default Routes;
