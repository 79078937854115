import React, { useState } from 'react';
import { CheckList } from '../Form/CheckList';
import ActivityBadge from '../shared/badges/ActivityBadge';
import { useBadge } from '../../customHooks/useBadge';
const CheckListActivity = ({ content, badge, identifier, submitID }) => {
  const [{ showBadge }, dispatch] = useBadge(false);
  return (
    <>
      {content.intro && <p className='padding-1 item-1'>{content.intro}</p>}
      <div className='question-wrapper'>
        {content.question && <p>{content.question}</p>}
        <CheckList
          submitID={submitID}
          options={content.options}
          fillIn={content.fillIn}
          max={content.max}
          updateShowBadge={() => dispatch({ type: 'show', payload: badge })}
          identifier={identifier}
        />
      </div>
      {content.outro && <p className='padding-1'>{content.outro}</p>}
      <ActivityBadge
        showBadge={showBadge}
        dispatch={dispatch}
        content={badge}
      />
    </>
  );
};
export default CheckListActivity;
