import React from "react";

const RadioInput = ({ handleChange, state, key, name, value, type }) => {
  return (
    <input
      key={key}
      name={name}
      value={value}
      type={type}
      onChange={handleChange}
      checked={state[name] === value}
    ></input>
  );
};

export default RadioInput;
