import { create } from 'zustand';

const useStore = create((set) => ({
  language: undefined,
  setLanguage: (language) => {
    window.Localize.setLanguage(language);
    set({ language: language });
  },
}));

export default useStore;
