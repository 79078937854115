import React from 'react';
import GenStyles from '../../../GenStyles';
import styled from 'styled-components';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';

const S = {
  LogInUl: styled.ul`
    max-height: 250px;
    overflow-y: scroll;
    width: fit-content;
    padding: 0;
    border: black solid 1px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    gap: 2px;
    background: black;
  `,

  LogInListItem: styled.li`
    list-style: none;
    padding: 0.5rem 1rem;
    background: white;
  `,
};

const Logins = ({ loginArray }) => {
  const mostRecentLoginFirstArray = [...loginArray].reverse();
  return (
    <GenStyles.UserItemWrapper>
      <GenStyles.InfoHeader>Log Ins</GenStyles.InfoHeader>
      <GenStyles.Header3>
        Last Login :
        <GenStyles.ImportantText>
          {format(parseISO(mostRecentLoginFirstArray[0].date), 'LL/dd/y')}
        </GenStyles.ImportantText>
      </GenStyles.Header3>
      <GenStyles.Header3>Previous Logins :</GenStyles.Header3>
      <S.LogInUl>
        {mostRecentLoginFirstArray.map((item) => (
          <S.LogInListItem>
            {format(parseISO(item.date), 'LL/dd/y')}
          </S.LogInListItem>
        ))}
      </S.LogInUl>
    </GenStyles.UserItemWrapper>
  );
};

export default Logins;
