import React, { useEffect, useState } from 'react';
import '../handouts/handouts.scss';
import allHandouts from './allHandouts';
import { ReactComponent as Icon } from '../../../assets/images/PDFs.svg';
import useStore from '../../../customHooks/useStore';
function Handout(props) {
  const { language } = useStore((state) => ({
    language: state.language,
  }));
  let handOut = props.content;

  return (
    <>
      <div className='handout-wrap'>
        {handOut ? (
          <a
            href={
              language === 'en'
                ? allHandouts[handOut].en
                : allHandouts[handOut].es
            }
            className='handout-button'
            target='_blank'
            rel='noreferrer'
          >
            <p>Review Handout</p>
            <Icon />
          </a>
        ) : (
          <></>
        )}
      </div>
    </>
  );
}

export default Handout;
